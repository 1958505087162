import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  MenuItem,
  TableBody,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Box,
  useMediaQuery,
} from '@mui/material';
import { TablePagination, TableCell, TableFooter, TableRow, TableHead } from '@material-ui/core';

// components
import { ToastContainer } from 'react-toastify';
import MaterialTable, { MTableBody } from 'material-table';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

// sections
// import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import InfoAlert from '../../components/alert/InfoAlert';
import FormatRupiah from '../../components/formatrupiah/FormatRupiah';
import USERLIST from '../../_mock/user';
import TablesIcons from '../../components/tableicon/TableIcon';
import { getDataLocation, listUser, listTransaction, reportTransactions } from '../../apiconfig/ApiConfig';
import ExpSessionModal from '../../components/expsessionmodal/ExpSessionModal';
import Iconify from '../../components/iconify';
import CapitalizeFirstLetter from '../../components/capitalizefirstletter/CapitalizeFirstLetter';
import ErrorAlert from '../../components/alert/ErrorAlert';
import DetailTransaction from './DetailTransaction';
import LogoParkways from '../../assets/logo/logo-parkways.png';
import WebIcon from '../../assets/logo/web.png';
import 'moment/locale/id';

moment.locale('id');

// ----------------------------------------------------------------------

const TransactionReport = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const [open, setOpen] = useState(null);
  const [openExportData, setOpenExportData] = useState(null);
  const [dataTransaksi, setDataTransaksi] = useState([]);
  const [detailDataTransaksi, setDetailDataTransaksi] = useState([]);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [detailTransactionModal, setDetailTransactionModal] = useState(false);
  const [selectedDetailTransaction, setSelectedDetailTransaction] = useState([]);
  const [loading, setLoading] = useState(false);

  const [location, setLocation] = useState({});

  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [openModalExpSession, setOpenModalExpSession] = useState(false);
  const navigate = useNavigate();

  const getDataTransaction = async () => {
    await axios
      .post(reportTransactions, {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(finishDate).format('YYYY-MM-DD'),
      })
      .then((response) => {
        // console.log('data transaksi : ', response.data);
        setDataTransaksi(response.data);
        if (response.data.transactions.length === 0) {
          ErrorAlert({
            message: 'Tidak Ada Data Transaksi',
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const dataLocation = async () => {
    await axios
      .post(getDataLocation)
      .then((response) => {
        // console.log('location : ', response.data);
        setLocation(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const token = localStorage.getItem('token');

  const HandleExpSession = () => {
    const expIn = localStorage.getItem('expSession');

    const expTime = new Date().getTime() > JSON.parse(expIn);
    // console.log('data storage : ', expTime);

    if (expTime) {
      setOpenModalExpSession(true);
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (token) {
      HandleExpSession();
      getDataTransaction();
      dataLocation();
    } else if (!token) {
      setOpenModalExpSession(true);
      setTimeout(() => {
        navigate('/');
      }, 9000);
    }
  }, [token]);

  const handleCloseModal = () => {
    setOpenModalExpSession(false);
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    // console.log(moment(startDate).format('YYYY-MM-DD'));
    // console.log(moment(finishDate).format('YYYY-MM-DD'));

    await axios
      .post(reportTransactions, {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(finishDate).format('YYYY-MM-DD'),
      })
      .then((response) => {
        // console.log('data transaksi : ', response.data);
        setDataTransaksi(response.data);
        if (response.data.length === 0) {
          ErrorAlert({
            message: 'Tidak Ada Data Transaksi',
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const columns = [
    {
      title: 'Tanggal & Waktu',
      field: 'tanggal',
      defaultSort: 'desc',
      sorting: true,
      cellStyle: {
        textAlign: 'left',
        fontSize: '11px',
        fontWeight: 'bold',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Tipe Pembayaran',
      field: 'payment_type',
      //   render: rowData => rowData.check_in_time,
      sorting: false,
      render: (rowData) =>
        rowData.payment_type === 'qris' ? 'QRIS' : rowData.payment_type === 'cash' ? 'Cash' : undefined,
      cellStyle: {
        // textAlign: 'center',
        fontSize: '11px',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Status',
      field: 'payment_status',
      render: (rowData) =>
        rowData.payment_status === 'success' ? (
          <span style={{ fontWeight: 'bolder', color: 'green' }}>
            {CapitalizeFirstLetter(rowData.payment_status === 'success' ? 'Lunas' : undefined)}
          </span>
        ) : rowData.payment_status === 'pending' ? (
          <span style={{ fontWeight: 'bolder', color: '#F0AA51' }}>
            {CapitalizeFirstLetter(rowData.payment_status === 'pending' ? 'Pending' : undefined)}
          </span>
        ) : rowData.payment_status === 'cancel' ? (
          <span style={{ fontWeight: 'bolder', color: 'crimson' }}>
            {CapitalizeFirstLetter(rowData.payment_status === 'cancel' ? 'Batal' : undefined)}
          </span>
        ) : rowData.payment_status === 'refund' ? (
          <span style={{ fontWeight: 'bolder', color: 'purple' }}>
            {CapitalizeFirstLetter(rowData.payment_status === 'refund' ? 'refund' : undefined)}
          </span>
        ) : undefined,
      sorting: false,
      cellStyle: {
        // textAlign: 'center',
        fontSize: '11px',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Total Harga',
      field: 'amount',
      render: (rowData) => <span style={{ fontWeight: 'bold' }}> {FormatRupiah(rowData.amount)} </span>,
      sorting: false,
      cellStyle: {
        // textAlign: 'center',
        fontSize: '11px',
        padding: isNonMobile ? '20px 0px 20px 40px' : undefined,
        // border: 'solid 1px #E0E0E0',
      },
      headerStyle: {
        // textAlign:'center'
      },
    },
  ];

  const FilterByDateRange = () => {
    /* function for adding 2 textfields for date range */
    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          // p="10px"
          mt={isNonMobile ? '10px' : undefined}
          sx={{
            '& > div': {
              gridColumn: isNonMobile ? undefined : 'span 4',
            },
          }}
        >
          <DatePicker
            label="Dari Tanggal"
            // localeText={{ toolbarTitle: 'Title' }}
            sx={{ gridColumn: 'span 2', padding: '0px' }}
            slotProps={{
              textField: { variant: 'outlined' },
              toolbar: {
                toolbarPlaceholder: '__',
                toolbarFormat: 'yyyy-mm-dd',
                hidden: true,
              },
            }}
            // slots={{
            //   toolbar: CustomToolbarComponent
            // }}
            value={startDate}
            onChange={(newValue) => {
              // console.log(newValue);
              setStartDate(newValue);
            }}
          />
          <DatePicker
            label="Sampai Tanggal"
            // localeText={{ toolbarTitle: 'Title' }}
            sx={{ gridColumn: 'span 2' }}
            slotProps={{
              textField: {
                variant: 'outlined',
                // sx: {
                //   //   padding: '0px auto', // Adjust the padding value according to your needs]
                //   //   fontSize:'10px',
                //   width: '200px',
                // },
              },
              toolbar: {
                toolbarPlaceholder: '__',
                toolbarFormat: 'yyyy-mm-dd',
                hidden: true,
              },
            }}
            // slots={{
            //   toolbar: CustomToolbarComponent
            // }}
            value={finishDate}
            onChange={(newValue) => {
              // console.log(newValue);
              setFinishDate(newValue);
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent={isNonMobile ? 'end' : 'end'}
          margin="20px 0 10px 0"
          // background='#012D70'
        >
          <LoadingButton
            type="submit"
            size="medium"
            endIcon={<Iconify icon={'ic:baseline-manage-search'} />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            sx={{
              fontWeight: 'bold',
              padding: '4px 15px',
            }}
          >
            Cari Data
          </LoadingButton>
        </Box>
      </form>
    );
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleActionButton = () => {
    return (
      <IconButton size="large" color="inherit">
        <Iconify icon={'tabler:list-details'} />
        {/* <Iconify icon={'eva:more-vertical-fill'} /> */}
      </IconButton>
    );
  };

  // fungsi untuk render summary data
  const renderSummaryData = (totalObj) => {
    // console.log('test', totalObj)
    return (
      <TableFooter>
        <TableRow>
          {dataTransaksi.length !== 0 ? (
            <>
              <TableCell style={{ fontSize: '11.5px' }}>
                <Typography style={{ fontWeight: 'bold', color: '#000', fontSize: '11.5px', textAlign: 'center' }}>
                  {FormatRupiah(totalObj.totalSum)}
                </Typography>
              </TableCell>
            </>
          ) : undefined}
        </TableRow>
      </TableFooter>
    );
  };

  const handleActionButtonExportData = () => {
    return (
      <IconButton size="large" color="inherit" onClick={handleOpenMenuExportData} sx={{ mr: '10px' }}>
        <Iconify icon={'prime:download'} width={25} />
      </IconButton>
    );
  };

  // Fungsi untuk export data ke format file CSV
  const handleExportExcel = () => {
    if (dataTransaksi.length !== 0) {
      console.log(dataTransaksi);
      // Membuat objek custom header
      const exportData = dataTransaksi.map((item) => ({
        Tanggal: item.tanggal,
        'Tipe Pembayaran': item.payment_type,
        Status:
          item.payment_status === 'success'
            ? 'Lunas'
            : item.payment_status === 'pending'
            ? 'Pending'
            : item.payment_status === 'cancel'
            ? 'Batal'
            : item.payment_status === 'refund'
            ? 'Refund'
            : undefined,
        'Total Harga': FormatRupiah(item.amount),
        // Total: FormatRupiah(item.sales),
      }));

      // Menghitung total
      const grandTotal = dataTransaksi.reduce((total, item) => total + parseInt(item.amount, 10), 0);

      // Membuat objek totalRow
      const totalRow = {
        Tanggal: 'GrandTotal',
        'Tipe Pembayaran': '',
        Status: '',
        'Total Harga': FormatRupiah(grandTotal),
      };

      // Menambahkan totalRow ke exportData
      exportData.push(totalRow);

      const workSheet = XLSX.utils.json_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'LaporanTransaksi');

      XLSX.writeFile(workBook, 'LaporanTransaksi.xlsx');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
  };

  // Fungsi untuk export data to PDF
  const handleExportPdf = () => {
    const doc = new JsPDF();

    // Mendefinisikan logo
    const logoUrl = LogoParkways;
    const logoWidth = 16;
    const logoHeight = 16;

    if (dataTransaksi.length !== 0) {
      const titleX = doc.internal.pageSize.getWidth() / 15.5; // Posisi horizontal (x-coordinate) di tengah halaman
      const titleY = 25; // Posisi vertikal (y-coordinate)

      doc.setFontSize(13);
      doc.setFont('helvetica', 'bold');
      doc.text('LaporanTransaksi', titleX, titleY);
      // doc.text('Laporan Pendapatan', 15, 8)

      // Menambahkan logo
      doc.addImage(logoUrl, 'PNG', 155, 10, logoWidth, logoHeight);
      // doc.setFont('helvetica', 'bold');
      doc.setTextColor('#1E458C');
      doc.text('Parkways', 170, 18);
      doc.setFontSize(5);
      doc.text('Digital Parking Transformation', 170, 21.5);

      doc.setTextColor('black');
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');
      doc.text(location.location_name ? `${location.location_name}` : 'Tidak ada nama lokasi', 14, 35);

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(7);
      doc.text(
        `Periode ${moment(startDate).format('Do MMM YYYY')} - ${moment(finishDate).format('Do MMM YYYY')}`,
        14,
        39.5
      );

      // Menggambar garis bawah pada judul
      doc.setLineWidth(0.1);
      doc.line(13, titleY + 2.5, doc.internal.pageSize.getWidth() - 13, titleY + 2);

      const startY = 42;

      doc.autoTable({
        startY,
        showHead: 'firstPage',
        rowPageBreak: 'avoid',
        headStyles: {
          valign: 'middle',
          halign: 'left',
          fontSize: 9,
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        columnStyles: {
          tanggal: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            // cellWidth: 55,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
          payment_type: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            // cellWidth: 50,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          },
          payment_status: {
            halign: 'left',
            valign: 'middle',
            // cellWidth: 50,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          },
          amount: {
            halign: 'right',
            valign: 'middle',
            // cellWidth: 20,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
        },

        columns: columns.map((col) => ({
          header: col.title,
          dataKey: col.field,
        })),

        body: dataTransaksi.map((data) => ({
          tanggal: data.tanggal,
          payment_type: data.payment_type === 'qris' ? 'QRIS' : 'Cash',
          payment_status:
            data.payment_status === 'success'
              ? 'Lunas'
              : data.payment_status === 'pending'
              ? 'Pending'
              : data.payment_status === 'cancel'
              ? 'Batal'
              : data.payment_status === 'refund'
              ? 'Refund'
              : undefined,
          amount: FormatRupiah(data.amount),
        })),
      });

      const grandTotal = dataTransaksi.reduce((total, data) => total + parseInt(data.amount, 10), 0);

      // console.log('grandTotal', grandTotal);

      doc.autoTable({
        body: [
          [
            {
              content: 'Grand Total',
              styles: {
                halign: 'left',
                valign: 'middle',
                fontSize: 9,
                fillColor: '#FFFFFF',
                // lineWidth: { bottom: 0.1 },
                // lineColor: '#000000',
                fontStyle: 'bold',
              },
            },
            {
              content: FormatRupiah(grandTotal),
              styles: {
                halign: 'right',
                valign: 'middle',
                fontSize: 9,
                fillColor: '#FFFFFF',
                // lineWidth: { bottom: 0.1 },
                // lineColor: '#000000',
                fontStyle: 'bold',
              },
            },
          ],
        ],
      });

      // Footer Section
      // Mendapatkan tanggal saat ini
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      const currentTime = moment(new Date()).format('HH:mm:ss');

      // Mendefinisikan ikon web
      const webIconUrl = WebIcon;
      const webIconWidth = 4;
      const webIconHeight = 4;

      // Menambahkan ikon web di sebelah kiri www.bssparking.com
      const webIconX1 = 127; // Koordinat X untuk ikon web
      const webIconY = doc.internal.pageSize.getHeight() - 13;

      // Memuat ikon web
      doc.addImage(webIconUrl, 'PNG', webIconX1, webIconY, webIconWidth, webIconHeight);

      // Mendefinisikan teks footer
      const leftFooterText = `Dicetak pada ${currentDate} ${currentTime}`;
      const rightFooterText1 = 'www.parkways.id';
      const rightFooterText2 = 'www.bssparking.com';

      // Menambahkan footer
      doc.setFontSize(8);
      doc.setTextColor('gray');

      // Teks footer di sebelah kiri
      doc.text(leftFooterText, 8 + webIconWidth + 2, doc.internal.pageSize.getHeight() - 10);
      doc.setFont('helvetica', 'bold');

      // Teks footer di sebelah kanan www.parkways.id
      const rightFooterTextWidth1 =
        (doc.getStringUnitWidth(rightFooterText1) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX1 = doc.internal.pageSize.getWidth() - rightFooterTextWidth1 - 13;
      doc.text(rightFooterText1, rightFooterTextX1, doc.internal.pageSize.getHeight() - 10);

      // Menambahkan ikon web di sebelah kanan www.parkways.id
      const webIconX2 = rightFooterTextX1 - webIconWidth - 2;
      doc.addImage(webIconUrl, 'PNG', webIconX2, webIconY, webIconWidth, webIconHeight);

      // Teks footer di sebelah kanan www.bssparking.com
      const rightFooterTextWidth2 =
        (doc.getStringUnitWidth(rightFooterText2) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX2 = webIconX2 - rightFooterTextWidth2 - 6;
      doc.text(rightFooterText2, rightFooterTextX2, doc.internal.pageSize.getHeight() - 10);

      doc.save('LaporanTransaksi.pdf');
      InfoAlert({
        message: 'Laporan tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
  };

  const handleOpenMenuExportData = (event) => {
    setOpenExportData(event.currentTarget);
  };

  const handleCloseMenuExportData = () => {
    setOpenExportData(null);
  };

  return (
    <>
      <Helmet>
        <title> Transaction | Parkways easyticket </title>
      </Helmet>

      <Container>
        <Box
          display="grid"
          gap="15px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          // p="10px"
          mt="5px"
          sx={{
            '& > div': {
              gridColumn: isNonMobile ? undefined : 'span 4',
              mb: '30px',
            },
          }}
        >
          <Box sx={{ gridColumn: 'span 2' }}>{''}</Box>
          <Box sx={{ gridColumn: 'span 2' }}>
            <FilterByDateRange />
          </Box>
        </Box>
        <Card sx={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)' }}>
          <MaterialTable
            icons={TablesIcons}
            columns={columns}
            data={dataTransaksi}
            localization={{
              header: {
                // actions: 'Aksi',
              },
              toolbar: {
                // exportTitle: 'Laporan Casual',
                exportCSVName: 'Export Excel',
                exportPDFName: 'Export PDF',
              },
            }}
            actions={[
              {
                icon: () => handleActionButton(),
                tooltip: 'Lihat Rincian Transaksi',
                onClick: (e, rowData) => {
                  setSelectedDetailTransaction(rowData.details);
                  console.log('selected data', rowData.details);
                  if (rowData.details.length > 0) {
                    setDetailTransactionModal(true);
                  } else {
                    InfoAlert({
                      message: 'Tidak ada data detail transaksi',
                    });
                  }
                },
              },
              {
                icon: () => handleActionButtonExportData(),
                tooltip: 'Export Data',
                onClick: (e, rowData) => {
                  // setSelectedUserData(rowData);
                  console.log(rowData);
                },
                isFreeAction: true,
              },
            ]}
            options={{
              // exportButton: {
              //   csv: true,
              //   pdf: true,
              // },
              // exportCsv: (data, col) => exportToXlsx(),
              // exportPdf: () => exportToPdf(),
              paging: true,
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              pageSizeOptions: [5, 10, 20, 50], // rows selection options
              // actionsCellStyle: { textAlign: 'center', alignSelf:'center', justifyContent:'center', alignItems:'center'},
              // search: false,
              paginationType: 'stepped',
              searchFieldAlignment: 'left',
              // searchAutoFocus: true,
              searchFieldVariant: 'outlined',
              searchFieldStyle: {
                margin: '20px auto 20px auto',
              },
              showTitle: false,
              actionsColumnIndex: -1,
              addRowPosition: 'first',
              // rowStyle: (data, index) =>
              //   index % 2 == 0 ? { background: '#f5f5f5' } : null,
              headerStyle: {
                fontWeight: 'bold',
                // textAlign: 'left',
                fontSize: '12px',
                padding: '10px 20px 10px 15px',
              },
            }}
          />
        </Card>
        <Popover
          open={Boolean(openExportData)}
          anchorEl={openExportData}
          onClose={handleCloseMenuExportData}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleExportExcel();
            }}
          >
            <Iconify icon={'vscode-icons:file-type-excel'} width={25} sx={{ mr: 2 }} />
            Excel
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleExportPdf();
            }}
          >
            <Iconify icon={'vscode-icons:file-type-pdf2'} width={25} sx={{ mr: 2 }} />
            Pdf
          </MenuItem>
        </Popover>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              // setOpenEditUserModal(true)
              // setOpen(false)
              setDetailTransactionModal(true);
            }}
          >
            <Iconify icon={'tabler:list-details'} sx={{ mr: 2 }} />
            Lihat Detail
          </MenuItem>
        </Popover>
      </Container>
      <DetailTransaction
        open={detailTransactionModal}
        close={() => setDetailTransactionModal(false)}
        detailTransactionData={selectedDetailTransaction}
      />
      <ExpSessionModal open={openModalExpSession} close={() => handleCloseModal()} />
      <ToastContainer />
    </>
  );
};

export default TransactionReport;
