// material-ui
import {
    Typography,
    Box,
    Button,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    TextField,
    OutlinedInput,
    IconButton,
    InputAdornment,
} from '@mui/material';

import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { createCategory, createItem, createUser, updateItem } from '../../apiconfig/ApiConfig';
import ErrorAlert from '../../components/alert/ErrorAlert';
import SuccessAlert from '../../components/alert/SuccessAlert';


const EditItem = ({
    open,
    close,
    dataCategory,
    getDataItem,
    selectedData
}) => {
    const isNonMobile = useMediaQuery('(min-width:600px)');

    // const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const [newData, setNewData] = useState({
        ticketingcategory_id: '',
        item_name: '',
        price: ''
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isNonMobile ? 400 : 360,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
    };

    const getData = () => {
        setNewData({
            ticketingcategory_id: selectedData.ticketingcategory_id,
            item_name: selectedData.item_name,
            price: selectedData.price
        })
    }

    useEffect(() => {
        // console.log(selectedData);
        if (selectedData.ticketitem_id) {
            getData()
        }
    }, [selectedData.ticketitem_id])



    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(newData.item_name);
        // console.log(newData.ticketingcategory_id);
        // console.log(newData.price);
        setLoading(true)
        if (newData.price < 0) {
            ErrorAlert({
                message: 'Jumlah harga tidak valid'
            })
            setLoading(false)
        } else {
            await axios.put(`${updateItem}/${selectedData.ticketitem_id}`, {
                ticketingcategory_id: newData.ticketingcategory_id,
                item_name: newData.item_name,
                price: newData.price
            }).then((response) => {
                // console.log(response.data);
                // clearFormData()
                close()
                setLoading(false)
                getDataItem()
                SuccessAlert({
                    message: response.data.success
                })
            }).catch((err) => {
                setLoading(false)
                console.log(err.response.data);
                ErrorAlert({
                    message: err.response.data.warning
                })
            });
        }


    }

    // Fungsi untuk menyimpan value yang diinput ke variabel newdata
    const handleChange = (e, props) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
        // userId = props.id
        // console.log([name], value);
        // dispatch(getDataStaff(newData))
    };

    const clearFormData = () => {
        setNewData({
            ticketingcategory_id: '',
            item_name: '',
            price: ''
        });
    }


    return (
        <div>
            <Modal open={open}>
                <Box sx={style}>
                    <form onSubmit={(e) => handleSubmit(e)} >
                        <Box
                            mb="35px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h4" textAlign="center">
                                Edit item
                            </Typography>
                        </Box>
                        <Box
                            justifyContent="center"
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            // p="10px 10px"
                            sx={{
                                '& > div': {
                                    gridColumn: isNonMobile ? undefined : 'span 4',
                                },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Nama Item"
                                // placeholder='giovr'
                                value={newData.item_name}
                                onChange={e => handleChange(e)}
                                name="item_name"
                                autoComplete="off"
                                required
                                sx={{ gridColumn: 'span 4' }}
                            />
                            <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
                                <InputLabel id="nama-staff-label">Pilih Category</InputLabel>
                                <Select
                                    name="ticketingcategory_id"
                                    id="category"
                                    value={newData.ticketingcategory_id}
                                    label="Pilih Category"
                                    onChange={(e, id) => {
                                        handleChange(e);
                                        // setUserId(id.props.id);
                                        // console.log(id.props.id);
                                    }}
                                    required
                                >
                                    {dataCategory.map((data) => [
                                        <MenuItem value={data.ticketingcategory_id}>{data.category_name}</MenuItem>
                                    ])}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="number"
                                label="Harga"
                                // placeholder='giovr'
                                value={newData.price}
                                onChange={e => handleChange(e)}
                                name="price"
                                autoComplete="off"
                                required
                                sx={{ gridColumn: 'span 4' }}
                            />
                        </Box>
                        <Box
                            sx={{
                                // marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                // marginRight: '10px',
                                marginTop: '40px',
                                marginBottom: '5px',
                            }}
                        >
                            <Button
                                // onClick={handleBackButton}
                                color="error"
                                variant="outlined"
                                size='medium'
                                onClick={() => {
                                    close();
                                    // clearFormData();
                                }}
                                sx={{
                                    marginRight: '15px',
                                    width: '70px',
                                    fontSize: '13px',
                                }}
                                disabled={loading}
                            >
                                Kembali
                            </Button>
                            <LoadingButton
                                type="submit"
                                size="medium"
                                loading={loading}
                                loadingIndicator="Loading…"
                                variant="contained"
                            >
                                Edit Item
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default EditItem;