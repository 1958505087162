// material-ui
import {
    Typography,
    Box,
    Button,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    TextField,
    OutlinedInput,
    IconButton,
    InputAdornment,
} from '@mui/material';

import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { createUser } from '../../apiconfig/ApiConfig';
import ErrorAlert from '../../components/alert/ErrorAlert';
import SuccessAlert from '../../components/alert/SuccessAlert';


const CreateUserModal = ({
    open,
    close,
    getUsersData,
}) => {
    const isNonMobile = useMediaQuery('(min-width:600px)');

    // const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const [newData, setNewData] = useState({
        username: '',
        email: '',
        password: '',
        role: '',
    });


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isNonMobile ? 400 : 360,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        // console.log(newData.username);
        // console.log(newData.password);
        // console.log(newData.email);
        // console.log(newData.role);

        await axios.post(createUser, {
            username: newData.username,
            password: newData.password,
            email: newData.email,
            role: newData.role,
            location_id: 1
        }).then((response) => {
            // console.log(response.data);
            clearFormData()
            close()
            setLoading(false)
            getUsersData()
            SuccessAlert({
                message: response.data.message
            })
        }).catch((err) => {
            console.log(err.response.data);
            ErrorAlert({
                message: err.response.data.error
            })
            setLoading(false)
        });

    }

    // Fungsi untuk menyimpan value yang diinput ke variabel newdata
    const handleChange = (e, props) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
        // userId = props.id
        // console.log([name], value);
        // dispatch(getDataStaff(newData))
    };

    const clearFormData = () => {
        setNewData({
            email: '',
            username: '',
            password: '',
            role: '',
        });
    }
    

    return (
        <div>
            <Modal open={open}>
                <Box sx={style}>
                    <form onSubmit={(e) => handleSubmit(e)} >
                        {/* <Box display="flex" justifyContent="end">
                    <Tooltip title="Tutup">
                      <IconButton onClick={close}>
                        <CloseCircleOutlined
                          style={{ fontSize: '20px', color: 'red' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
                        <Box
                            mb="35px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h4" textAlign="center">
                                Membuat User Baru
                            </Typography>
                        </Box>
                        <Box
                            justifyContent="center"
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            // p="10px 10px"
                            sx={{
                                '& > div': {
                                    gridColumn: isNonMobile ? undefined : 'span 4',
                                },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Username"
                                placeholder='giovr'
                                value={newData.username}
                                onChange={e => handleChange(e)}
                                name="username"
                                autoComplete="off"
                                required
                                sx={{ gridColumn: 'span 4' }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                label="Password"
                                placeholder='*******'
                                value={newData.password}
                                onChange={e => handleChange(e)}
                                name="password"
                                autoComplete="off"
                                InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? (
                                                    <EyeOutlined />
                                                ) : (
                                                    <EyeInvisibleOutlined />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                sx={{ gridColumn: 'span 4' }}
                            />
                            <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
                                <InputLabel id="nama-staff-label">Pilih Role</InputLabel>
                                <Select
                                    name="role"
                                    id="user-role"
                                    value={newData.role}
                                    label="Pilih Role"
                                    onChange={(e, id) => {
                                        handleChange(e);
                                        // setUserId(id.props.id);
                                        // console.log(id.props.id);
                                    }}
                                    required
                                >
                                    <MenuItem value='admin' >Admin</MenuItem>
                                    <MenuItem value='kiosk' >Kiosk</MenuItem>
                                    <MenuItem value='kasir' >Kasir</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Email"
                                placeholder='gio@gmail.com'
                                value={newData.email}
                                onChange={e => handleChange(e)}
                                name="email"
                                autoComplete="off"
                                required
                                sx={{ gridColumn: 'span 4' }}
                            />
                        </Box>
                        <Box
                            sx={{
                                // marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                // marginRight: '10px',
                                marginTop: '40px',
                                marginBottom: '5px',
                            }}
                        >
                            <Button
                                // onClick={handleBackButton}
                                color="error"
                                variant="outlined"
                                onClick={() => {
                                    close();
                                    clearFormData();
                                }}
                                sx={{
                                    marginRight: '15px',
                                    width: '70px',
                                    fontSize: '13px',
                                }}
                                disabled={loading}
                            >
                                Kembali
                            </Button>
                            <LoadingButton
                                type="submit"
                                size="medium"
                                loading={loading}
                                loadingIndicator="Loading…"
                                variant="contained"
                            >
                                Buat User
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default CreateUserModal;