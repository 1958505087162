import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon('ic_analytics'),
    
  },
  {
    title: 'user',
    path: '/user',
    icon: icon('ic_user'),
    
  },
  {
    title: 'location',
    path: '/location',
    icon: icon('location-radius'),
    
  },
  {
    title: 'category',
    path: '/category',
    icon: icon('ic_category'),
    
  },
  {
    title: 'items',
    path: '/items',
    icon: icon('ic_item'),
    
  },
  {
    title: 'member',
    path: '/member',
    icon: icon('light-member'),
    
  },
  {
    title: 'Gate Access',
    path: '/gateaccess',
    icon: icon('boom-gate-arrow-up'),
    
  },
  {
    title: 'transaction',
    path: '/transaction',
    icon: icon('report-search'),
    
  }
];

export default navConfig;
