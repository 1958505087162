import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import User from './pages/user/User';
import Category from './pages/category/Category';
import Items from './pages/items/Items';
import Dashboard from './pages/dashboard/Dashboard';
import TransactionReport from './pages/report/TransactionReport';
import Location from './pages/location/Location';
import GateAccessReport from './pages/report/GateAccessReport';
import Member from './pages/member/Member';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      // path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/dashboard', element: <Dashboard />, index: true },
        // { path: '/user', element: <UserPage /> },
        { path: '/user', element: <User /> },
        { path: '/location', element: <Location /> },
        { path: '/category', element: <Category /> },
        { path: '/items', element: <Items /> },
        { path: '/member', element: <Member /> },
        { path: '/transaction', element: <TransactionReport /> },
        { path: '/gateaccess', element: <GateAccessReport /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}


