import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';

// components
import { ToastContainer } from 'react-toastify';
import MaterialTable from 'material-table';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


// sections
// import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import TablesIcons from '../../components/tableicon/TableIcon';
import { getDataLocation, listMember, listUser } from '../../apiconfig/ApiConfig';
import ExpSessionModal from '../../components/expsessionmodal/ExpSessionModal';
import Iconify from '../../components/iconify';
import CreateMember from './CreateMember';
import EditMember from './EditMember';
import DeleteMember from './DeleteMember';
import QRCodeModal from './QRCodeModal';

// ----------------------------------------------------------------------

const User = () => {

    const [open, setOpen] = useState(null);
    const [dataMember, setDataMember] = useState([])
    const [openCreateMemberModal, setOpenCreateMemberModal] = useState(false)
    const [openEditMemberModal, setOpenEditMemberModal] = useState(false)
    const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false)
    const [selectedDataMember, setSelectedDataMember] = useState({})

    const navigate = useNavigate()
    const [openModalExpSession, setOpenModalExpSession] = useState(false)
    const [OpenQRCodeModal, setOpenQRCodeModal] = useState(false)



    const getDataMember = async () => {
        await axios.post(listMember)
            .then((response) => {
                // console.log(response.data);
                setDataMember(response.data)
            }).catch((error) => {
                console.log(error.response);
            });
    }

    const token = localStorage.getItem("token");

    const HandleExpSession = () => {
        const expIn = localStorage.getItem("expSession");

        const expTime = (new Date()).getTime() > JSON.parse(expIn)
        // console.log('data storage : ', expTime);    

        if (expTime) {
            setOpenModalExpSession(true)
            localStorage.clear()
        }
    }

    useEffect(() => {
        if (token) {
            HandleExpSession()
            getDataMember()
        } else if (!token) {
            setOpenModalExpSession(true)
            setTimeout(() => {
                navigate('/');
            }, 9000);
        }

    }, [token]);


    const handleCloseModal = () => {
        setOpenModalExpSession(false)
        navigate('/');
    }



    const columns =
        [
            {
                title: 'Nama Member',
                field: 'name',
                defaultSort: 'asc',
                // sorting: false,
                cellStyle: {
                    textAlign: 'left',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    // border: 'solid 1px #E0E0E0',
                },

            },
            {
                title: 'Kode Member',
                field: 'member_code',
                //   render: rowData => rowData.check_in_time,
                sorting: false,
                cellStyle: {
                    // textAlign: 'center',
                    fontSize: '11px',
                    // border: 'solid 1px #E0E0E0',
                },
            },
            {
                title: 'Nomor Telepon',
                field: 'phone',
                //   render: rowData => rowData.check_in_time,
                sorting: false,
                cellStyle: {
                    // textAlign: 'center',
                    fontSize: '11px',
                    // border: 'solid 1px #E0E0E0',
                },
            },
            {
                title: 'Masa Berakhir',
                field: 'time_limit',
                //   render: rowData => rowData.check_in_time,
                sorting: false,
                cellStyle: {
                    // textAlign: 'center',
                    fontSize: '11px',
                    // border: 'solid 1px #E0E0E0',
                },
            },
        ]

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };


    const handleActionButton = () => {
        return (
            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
        )
    }

    const handleCreateUser = () => {
        setOpenCreateMemberModal(true)
    }

    return (
        <>
            <Helmet>
                <title> Member | Parkways easyticket </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h5" gutterBottom>
                        Management Member
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateUser}>
                        Member Baru
                    </Button>
                </Stack>

                <Card sx={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)' }}>
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                    <MaterialTable
                        icons={TablesIcons}
                        columns={columns}
                        data={dataMember}
                        localization={{
                            header: {
                                // actions: 'Aksi',
                            },
                            toolbar: {
                                // exportTitle: 'Laporan Casual',
                                exportCSVName: "Export Excel",
                                exportPDFName: "Export PDF"
                            }
                        }}
                        actions={
                            [
                                {
                                    icon: () => handleActionButton(),
                                    tooltip: 'Action Button',
                                    onClick: (e, rowData) => {
                                        setSelectedDataMember(rowData)
                                        // console.log(rowData);
                                    },
                                },
                            ]

                        }
                        options={{
                            //   exportButton: {
                            //     csv: memberDataSwitch === 'Semua Data' ? true : false,
                            //     pdf: true,
                            //   },
                            //   exportCsv: (data, col) => exportToXlsx(),
                            //   exportPdf: () => exportToPdf(),
                            paging: true,
                            pageSize: 10, // make initial page size
                            emptyRowsWhenPaging: false, // To avoid of having empty rows
                            pageSizeOptions: [5, 10, 20, 50], // rows selection options
                            // actionsCellStyle: { textAlign: 'center', alignSelf:'center', justifyContent:'center', alignItems:'center'},
                            // search: false,
                            paginationType: 'stepped',
                            searchFieldAlignment: 'left',
                            // searchAutoFocus: true,
                            searchFieldVariant: 'outlined',
                            searchFieldStyle: {
                                margin: '20px auto 20px auto',
                            },
                            showTitle: false,
                            actionsColumnIndex: -1,
                            addRowPosition: 'first',
                            // rowStyle: (data, index) =>
                            //   index % 2 == 0 ? { background: '#f5f5f5' } : null,
                            headerStyle: {
                                fontWeight: 'bold',
                                // textAlign: 'left',
                                fontSize: '12px',
                                padding: '10px 20px 10px 15px'
                            },
                        }}
                        // components={{
                        //     Header: props => {
                        //         return (
                        //             memberData.length !== 0 ? <>
                        //                 <TableRow>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Nama Member</TableCell>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Nomor Plat</TableCell>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Telepon</TableCell>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Kode Member</TableCell>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Periode Langganan</TableCell>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Masa Berakhir</TableCell>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >{memberDataSwitch === "Semua Data" ? "Status" : "Masa Berlaku"}</TableCell>
                        //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Aksi</TableCell>
                        //                 </TableRow>
                        //             </> : undefined
                        //         )
                        //     }
                        // }}
                        editable={{
                            // Fungsi untuk menghapus data member
                            // onRowDelete: oldData =>
                            //     new Promise((resolve, reject) => {
                            //         axios.delete(dataMemberAPI + '/' + oldData.id).then(() => {
                            //             errorAlert({
                            //                 message: 'Berhasil menghapus data',
                            //             });
                            //             getDataMember();
                            //             resolve();
                            //         });
                            //     }),
                        }}
                    />
                </Card>
                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 160,
                            '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >

                    <MenuItem
                        onClick={() => {
                            setOpenQRCodeModal(true)
                            setOpen(false)
                        }}>
                        <Iconify icon={'ri:qr-code-line'} sx={{ mr: 2 }} />
                        Generate QR
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setOpenEditMemberModal(true)
                            setOpen(false)
                        }}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setOpenDeleteMemberModal(true)
                            setOpen(false)
                        }}
                        sx={{ color: 'error.main' }}
                    >
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                        Delete
                    </MenuItem>

                </Popover>
                <CreateMember
                    open={openCreateMemberModal}
                    close={() => setOpenCreateMemberModal(false)}
                    getDataMember={() => getDataMember()}
                />
                <EditMember
                    open={openEditMemberModal}
                    close={() => setOpenEditMemberModal(false)}
                    getDataMember={() => getDataMember()}
                    selectedDataMember={selectedDataMember}
                />
                <DeleteMember
                    open={openDeleteMemberModal}
                    close={() => setOpenDeleteMemberModal(false)}
                    getDataMember={() => getDataMember()}
                    selectedDataMember={selectedDataMember}
                />
                <QRCodeModal 
                    open={OpenQRCodeModal}
                    close={() => setOpenQRCodeModal(false)}
                    selectedDataMember={selectedDataMember}
                />
            </Container>
            <ExpSessionModal open={openModalExpSession} close={() => handleCloseModal()} />
            <ToastContainer />
        </>
    );
}

export default User
