import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  useMediaQuery,
} from '@mui/material';

// components
import { ToastContainer } from 'react-toastify';
import MaterialTable from 'material-table';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

// sections
// import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import FormatRupiah from '../../components/formatrupiah/FormatRupiah';
import USERLIST from '../../_mock/user';
import TablesIcons from '../../components/tableicon/TableIcon';
import { getDataLocation, listUser, listTransaction, gateAccessReport } from '../../apiconfig/ApiConfig';
import ExpSessionModal from '../../components/expsessionmodal/ExpSessionModal';
import Iconify from '../../components/iconify';
import CapitalizeFirstLetter from '../../components/capitalizefirstletter/CapitalizeFirstLetter';
import ErrorAlert from '../../components/alert/ErrorAlert';
import InfoAlert from '../../components/alert/InfoAlert';
import LogoParkways from '../../assets/logo/logo-parkways.png';
import WebIcon from '../../assets/logo/web.png';
import 'moment/locale/id';

moment.locale('id');

// ----------------------------------------------------------------------

const TransactionReport = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const [open, setOpen] = useState(null);
  const [openExportData, setOpenExportData] = useState(null);
  const [dataGateAccess, setDataGateAccess] = useState([]);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({});
  const [location, setLocation] = useState({});
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const navigate = useNavigate();
  const [openModalExpSession, setOpenModalExpSession] = useState(false);

  const getDataGateAccess = async () => {
    await axios
      .post(gateAccessReport, {
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(finishDate).format('YYYY-MM-DD'),
      })
      .then((response) => {
        // console.log('data gateaccess : ', response.data.result);
        setDataGateAccess(response.data.result);
        if (response.data.result.length === 0) {
          ErrorAlert({
            message: 'Tidak ada data hari ini',
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const dataLocation = async () => {
    await axios.post(getDataLocation).then((response) => {
      // console.log('location : ', response.data)
      setLocation(response.data)
    }).catch((error) => {
      console.log(error.response.data);
    })
  }

  const token = localStorage.getItem('token');

  const HandleExpSession = () => {
    const expIn = localStorage.getItem('expSession');

    const expTime = new Date().getTime() > JSON.parse(expIn);
    // console.log('data storage : ', expTime);

    if (expTime) {
      setOpenModalExpSession(true);
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (token) {
      HandleExpSession();
      getDataGateAccess();
      dataLocation()
    } else if (!token) {
      setOpenModalExpSession(true);
      setTimeout(() => {
        navigate('/');
      }, 9000);
    }
  }, [token]);

  const handleCloseModal = () => {
    setOpenModalExpSession(false);
    navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    await axios
      .post(gateAccessReport, {
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(finishDate).format('YYYY-MM-DD'),
      })
      .then((response) => {
        // console.log('data Gate Access By Date : ', response.data.result);
        setDataGateAccess(response.data.result);
        if (response.data.result.length === 0) {
          ErrorAlert({
            message: 'Tidak Ada Data',
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const columns = [
    {
      title: 'Nama Kategori',
      field: 'category_name',
      // defaultSort: 'asc',
      sorting: false,
      cellStyle: {
        textAlign: 'left',
        fontSize: '11px',
        fontWeight: 'bold',
        width: '28%',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Nama Item',
      field: 'item_name',
      // defaultSort: 'asc',
      sorting: false,
      cellStyle: {
        textAlign: 'left',
        // width: '25%',
        fontSize: '11px',
        // fontWeight: 'bold',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Kode Tiket',
      field: 'ticket_code',
      // defaultSort: 'asc',
      sorting: false,
      cellStyle: {
        textAlign: 'left',
        // width: '25%',
        fontSize: '11px',
        // fontWeight: 'bold',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Status Gate',
      field: 'scanned',
      sorting: false,
      cellStyle: {
        // textAlign: 'center',
        fontSize: '11px',
        // width: '25%',
        // border: 'solid 1px #E0E0E0',
      },
      render: (rowData) =>
        rowData.scanned === true ? (
          <span style={{ fontWeight: 'bold', color: 'green' }}>
            {CapitalizeFirstLetter(rowData.scanned === true ? 'Sudah masuk' : undefined)}
          </span>
        ) : (
          <span style={{ fontWeight: 'bold', color: 'crimson' }}>
            {CapitalizeFirstLetter(rowData.scanned === null ? 'Belum Masuk' : undefined)}
          </span>
        ),
    },
    {
      title: 'Waktu Pembelian',
      field: 'purchased_at',
      // defaultSort: 'asc',
      sorting: false,
      // render: rowData => rowData.purchased_at ? rowData.purchased_at : '-',
      cellStyle: {
        textAlign: 'left',
        fontSize: '11px',
        // width: '25%',
        // fontWeight: 'bold',
        // border: 'solid 1px #E0E0E0',
      },
    },
  ];

  const FilterByDateRange = () => {
    /* function for adding 2 textfields for date range */
    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          // p="10px"
          mt={isNonMobile ? '10px' : undefined}
          sx={{
            '& > div': {
              gridColumn: isNonMobile ? undefined : 'span 4',
            },
          }}
        >
          <DatePicker
            label="Dari Tanggal"
            // localeText={{ toolbarTitle: 'Title' }}
            sx={{ gridColumn: 'span 2', padding: '0px' }}
            slotProps={{
              textField: { variant: 'outlined' },
              toolbar: {
                toolbarPlaceholder: '__',
                toolbarFormat: 'yyyy-mm-dd',
                hidden: true,
              },
            }}
            // slots={{
            //   toolbar: CustomToolbarComponent
            // }}
            value={startDate}
            onChange={(newValue) => {
              // console.log(newValue);
              setStartDate(newValue);
            }}
          />
          <DatePicker
            label="Sampai Tanggal"
            // localeText={{ toolbarTitle: 'Title' }}
            sx={{ gridColumn: 'span 2' }}
            slotProps={{
              textField: {
                variant: 'outlined',
                // sx: {
                //   //   padding: '0px auto', // Adjust the padding value according to your needs]
                //   //   fontSize:'10px',
                //   width: '200px',
                // },
              },
              toolbar: {
                toolbarPlaceholder: '__',
                toolbarFormat: 'yyyy-mm-dd',
                hidden: true,
              },
            }}
            // slots={{
            //   toolbar: CustomToolbarComponent
            // }}
            value={finishDate}
            onChange={(newValue) => {
              // console.log(newValue);
              setFinishDate(newValue);
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent={isNonMobile ? 'end' : 'end'}
          margin="20px 0 10px 0"
          // background='#012D70'
        >
          <LoadingButton
            type="submit"
            size="medium"
            endIcon={<Iconify icon={'ic:baseline-manage-search'} />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
            sx={{
              fontWeight: 'bold',
              padding: '4px 15px',
            }}
          >
            Cari Data
          </LoadingButton>
        </Box>
      </form>
    );
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleActionButton = () => {
    return (
      <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
        <Iconify icon={'eva:more-vertical-fill'} />
      </IconButton>
    );
  };

  const handleActionButtonExportData = () => {
    return (
      <IconButton size="large" color="inherit" onClick={handleOpenMenuExportData} sx={{ mr: '10px' }}>
        <Iconify icon={'prime:download'} width={25} />
      </IconButton>
    );
  };

  const handleOpenMenuExportData = (event) => {
    setOpenExportData(event.currentTarget);
  };

  const handleCloseMenuExportData = () => {
    setOpenExportData(null);
  };

  // Fungsi untuk export data ke format file CSV
  const handleExportExcel = () => {
    if (dataGateAccess.length !== 0) {
      // Membuat objek custom header
      const exportData = dataGateAccess.map((item) => ({
        'Nama Kategori': item.category_name,
        'Nama Item': item.item_name,
        'Kode Tiket': item.ticket_code,
        'Status Gate': item.scanned === null ? 'Belum Masuk' : 'Sudah Masuk',
        'Waktu Pembelian': item.purchased_at,
        // Total: FormatRupiah(item.sales),
      }));

      const workSheet = XLSX.utils.json_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'GateAccess');

      XLSX.writeFile(workBook, 'GateAccess.xlsx');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
  };

  // Fungsi untuk export data to PDF
  const handleExportPdf = () => {
    const doc = new JsPDF();

    // Mendefinisikan logo
    const logoUrl = LogoParkways;
    const logoWidth = 16;
    const logoHeight = 16;

    // const columns = [
    //   {
    //     title: 'Bulan & Tahun',
    //     field: 'month',
    //   },
    //   {
    //     title: 'Total',
    //     field: 'sales',
    //   },
    // ];

    if (dataGateAccess.length !== 0) {
      const titleX = doc.internal.pageSize.getWidth() / 15.5; // Posisi horizontal (x-coordinate) di tengah halaman
      const titleY = 25; // Posisi vertikal (y-coordinate)

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text('Gate Access', titleX, titleY);

      // Menambahkan logo
      doc.addImage(logoUrl, 'PNG', 155, 10, logoWidth, logoHeight);
      doc.setTextColor('#1E458C');
      doc.text('Parkways', 170, 18);
      doc.setFontSize(5);
      doc.text('Digital Parking Transformation', 170, 21.5);

      doc.setTextColor('black');
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');
      doc.text(location.location_name ? `${location.location_name}` : 'Tidak ada nama lokasi', 14, 35)

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(7);
      doc.text(`Periode ${moment(startDate).format('Do MMM YYYY')} - ${moment(finishDate).format('Do MMM YYYY')}`, 14, 39.5)

      // Menggambar garis bawah pada judul
      doc.setLineWidth(0.1);
      doc.line(13, titleY + 2.5, doc.internal.pageSize.getWidth() - 13, titleY + 2);

      const startY = 42;

      // let grandTotal = 0;

      // const rows = dataGateAccess.map((data) => {
      //   const sales = data.sales ? parseInt(data.sales, 10) : 0;
      //   grandTotal += sales;

      //   return {
      //     month: dataGateAccess.month,
      //     sales: FormatRupiah(data.sales),
      //   };
      // });

      // // Tambahkan Grand Total ke array rows
      // rows.push({
      //   month: 'Grand Total',
      //   sales: FormatRupiah(grandTotal),
      // });

      doc.autoTable({
        startY,
        showHead: 'firstPage',
        rowPageBreak: 'avoid',
        headStyles: {
          valign: 'middle',
          halign: 'left',
          fontSize: 9,
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        columnStyles: {
          category_name: {
            halign: 'left',
            valign: 'middle',
            // cellWidth: 153,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
          item_name: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            // fontStyle: 'bold',
          },
          ticket_code: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            // fontStyle: 'bold',
          },
          scanned: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            // fontStyle: 'bold',
          },
          purchased_at: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            // fontStyle: 'bold',
          },
        },

        columns: columns.map((col) => ({
          header: col.title,
          dataKey: col.field,
        })),
        body: dataGateAccess.map((data) => ({
          category_name: data.category_name,
          item_name: data.item_name,
          ticket_code: data.ticket_code,
          scanned: data.scanned === null ? 'Belum Masuk' : 'Sudah Masuk',
          purchased_at: data.purchased_at,
        })),
      });

      // Footer Section
      // Mendapatkan tanggal saat ini
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      const currentTime = moment(new Date()).format('HH:mm:ss');

      // Mendefinisikan ikon web
      const webIconUrl = WebIcon;
      const webIconWidth = 4;
      const webIconHeight = 4;

      // Menambahkan ikon web di sebelah kiri www.bssparking.com
      const webIconX1 = 127; // Koordinat X untuk ikon web
      const webIconY = doc.internal.pageSize.getHeight() - 13;

      // Memuat ikon web
      doc.addImage(webIconUrl, 'PNG', webIconX1, webIconY, webIconWidth, webIconHeight);

      // Mendefinisikan teks footer
      const leftFooterText = `Dicetak pada ${currentDate} ${currentTime}`;
      const rightFooterText1 = 'www.parkways.id';
      const rightFooterText2 = 'www.bssparking.com';

      // Menambahkan footer
      doc.setFontSize(8);
      doc.setTextColor('gray');

      // Teks footer di sebelah kiri
      doc.text(leftFooterText, 8 + webIconWidth + 2, doc.internal.pageSize.getHeight() - 10);
      doc.setFont('helvetica', 'bold');

      // Teks footer di sebelah kanan www.parkways.id
      const rightFooterTextWidth1 =
        (doc.getStringUnitWidth(rightFooterText1) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX1 = doc.internal.pageSize.getWidth() - rightFooterTextWidth1 - 13;
      doc.text(rightFooterText1, rightFooterTextX1, doc.internal.pageSize.getHeight() - 10);

      // Menambahkan ikon web
      const webIconX2 = rightFooterTextX1 - webIconWidth - 2;
      doc.addImage(webIconUrl, 'PNG', webIconX2, webIconY, webIconWidth, webIconHeight);

      // Teks footer di sebelah kanan www.bssparking.com
      const rightFooterTextWidth2 =
        (doc.getStringUnitWidth(rightFooterText2) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX2 = webIconX2 - rightFooterTextWidth2 - 6;
      doc.text(rightFooterText2, rightFooterTextX2, doc.internal.pageSize.getHeight() - 10);

      doc.save('GateAccess.pdf');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Gate Access | Parkways easyticket </title>
      </Helmet>

      <Container>
        <Box
          display="grid"
          gap="15px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          // p="10px"
          mt="5px"
          sx={{
            '& > div': {
              gridColumn: isNonMobile ? undefined : 'span 4',
              mb: '30px',
            },
          }}
        >
          <Box sx={{ gridColumn: 'span 2' }}>{''}</Box>
          <Box sx={{ gridColumn: 'span 2' }}>
            <FilterByDateRange />
          </Box>
        </Box>
        <Card sx={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)' }}>
          {/* <Typography variant="h4" gutterBottom>
                        Management User
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateUser}>
                        User Baru
                    </Button> */}

          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
          <MaterialTable
            icons={TablesIcons}
            columns={columns}
            data={dataGateAccess}
            localization={{
              header: {
                // actions: 'Aksi',
              },
              toolbar: {
                // exportTitle: 'Laporan Casual',
                exportCSVName: 'Export Excel',
                exportPDFName: 'Export PDF',
              },
            }}
            actions={[
              {
                icon: () => handleActionButtonExportData(),
                tooltip: 'Export Data',
                onClick: (e, rowData) => {
                  setSelectedUserData(rowData);
                  // console.log(rowData);
                },
                isFreeAction: true,
              },
            ]}
            options={{
              //   exportButton: {
              //     csv: memberDataSwitch === 'Semua Data' ? true : false,
              //     pdf: true,
              //   },
              //   exportCsv: (data, col) => exportToXlsx(),
              //   exportPdf: () => exportToPdf(),
              paging: true,
              pageSize: 5, // make initial page size
              emptyRowsWhenPaging: false, // To avoid of having empty rows
              pageSizeOptions: [5, 10, 20, 50], // rows selection options
              // actionsCellStyle: { textAlign: 'center', alignSelf:'center', justifyContent:'center', alignItems:'center'},
              // search: false,
              paginationType: 'stepped',
              searchFieldAlignment: 'left',
              // searchAutoFocus: true,
              searchFieldVariant: 'outlined',
              searchFieldStyle: {
                margin: '20px auto 20px auto',
              },
              showTitle: false,
              actionsColumnIndex: -1,
              addRowPosition: 'first',
              // rowStyle: (data, index) =>
              //   index % 2 == 0 ? { background: '#f5f5f5' } : null,
              headerStyle: {
                fontWeight: 'bold',
                // textAlign: 'left',
                fontSize: '12px',
                padding: '10px 20px 10px 15px',
              },
            }}
            // components={{
            //     Header: props => {
            //         return (
            //             memberData.length !== 0 ? <>
            //                 <TableRow>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Nama Member</TableCell>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Nomor Plat</TableCell>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Telepon</TableCell>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Kode Member</TableCell>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Periode Langganan</TableCell>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Masa Berakhir</TableCell>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >{memberDataSwitch === "Semua Data" ? "Status" : "Masa Berlaku"}</TableCell>
            //                     <TableCell style={{ border: 'solid 1px #E0E0E0', fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' }} >Aksi</TableCell>
            //                 </TableRow>
            //             </> : undefined
            //         )
            //     }
            // }}
            editable={
              {
                // Fungsi untuk menghapus data member
                // onRowDelete: oldData =>
                //     new Promise((resolve, reject) => {
                //         axios.delete(dataMemberAPI + '/' + oldData.id).then(() => {
                //             errorAlert({
                //                 message: 'Berhasil menghapus data',
                //             });
                //             getDataMember();
                //             resolve();
                //         });
                //     }),
              }
            }
          />
        </Card>
        <Popover
          open={Boolean(openExportData)}
          anchorEl={openExportData}
          onClose={handleCloseMenuExportData}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              handleExportExcel();
            }}
          >
            <Iconify icon={'vscode-icons:file-type-excel'} width={25} sx={{ mr: 2 }} />
            Excel
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleExportPdf();
            }}
          >
            <Iconify icon={'vscode-icons:file-type-pdf2'} width={25} sx={{ mr: 2 }} />
            Pdf
          </MenuItem>
        </Popover>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              // setOpenEditUserModal(true)
              // setOpen(false)
            }}
          >
            <Iconify icon={'tabler:list-details'} sx={{ mr: 2 }} />
            Lihat Detail
          </MenuItem>
        </Popover>
      </Container>
      <ExpSessionModal open={openModalExpSession} close={() => handleCloseModal()} />
      <ToastContainer />
    </>
  );
};

export default TransactionReport;
