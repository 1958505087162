// material-ui
import {
  Typography,
  Box,
  Button,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import axios from 'axios';
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { createMember, createUser, updateMember } from '../../apiconfig/ApiConfig';
import ErrorAlert from '../../components/alert/ErrorAlert';
import SuccessAlert from '../../components/alert/SuccessAlert';
import InfoAlert from '../../components/alert/InfoAlert';
import Iconify from '../../components/iconify';

const QRCodeModal = ({ open, close, getDataMember, selectedDataMember }) => {

  const isNonMobile = useMediaQuery('(min-width:600px)');
  const qrcodeRef = useRef();

  const [LoadingDownloadQRIS, setLoadingDownloadQRIS] = useState(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
  };

  const handleClick = () => {
    setLoadingDownloadQRIS(true)
    const element = qrcodeRef.current;

    html2canvas(element).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, "qris.png");
      });
      setLoadingDownloadQRIS(false)
    });
  }

  return (
    <Modal open={open} onClose={close}>
      <Box sx={style}>
        <Box

          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            // padding: '8px 10px 6px 10px',
          }}
        >
          <div
            ref={qrcodeRef}
            style={{
              padding: '8px 10px 6px 10px',
              // border: LoadingDownloadQRIS ? undefined : 'solid 3px #7f4dff',
              borderRadius: '10px',
            }}
          >
            <QRCode
              value={selectedDataMember.member_code ? selectedDataMember.member_code : ''}
              size={400}
              style={{
                height: "200px",
                maxWidth: "200px",
                width: "200px",
                // padding: '10px',
              }}
              viewBox={`0 0 256 256`}
            />
          </div>
          <Button
            fullWidth
            onClick={handleClick}
            variant='contained'
            sx={{
              backgroundColor: '#7f4dff',
              color: 'white'

            }}
          >
            {LoadingDownloadQRIS ?
              <Iconify icon={'svg-spinners:tadpole'} />
              :
              'Download QR'
            }
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default QRCodeModal;
