import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

// material-ui
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';


// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from '../../../apiconfig/ApiConfig';
import SuccessAlert from '../../../components/alert/SuccessAlert';
import ErrorAlert from '../../../components/alert/ErrorAlert';


// ----------------------------------------------------------------------

const LoginForm = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  // const [username, setUsername] = useState('')
  // const [password, setPassword] = useState('')

  const handleClick = () => {
    // navigate('/dashboard', { replace: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('clicked');
  }

  const handleChange = (e) => {
    console.log(e.targe.value);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const token = localStorage.getItem("token");
  // const item = localStorage.getItem('item');

  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    }
  }, []);


  return (
    <Box>
      <Formik
        initialValues={{
          username: 'admin',
          password: 'admin',
          // submit: null,
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().max(30).required('Username wajib diisi'),
          password: Yup.string().max(30).required('Password wajib diisi'),
        })}
        onSubmit={async (
          values,
          { setErrors, setStatus, setSubmitting },
        ) => {
          // console.log(values);
          setLoading(true)
          axios.post(login, {
            username: values.username,
            password: values.password
          })
            .then((response) => {

              const expSession = new Date().setMinutes(new Date().getMinutes() + 60)

              localStorage.setItem('token', response.data.access_token);
              localStorage.setItem('expSession', expSession);
              // localStorage.setItem('expIn', expSession);

              // setLoading(false)
              console.log(response.data);
              
              SuccessAlert({
                message: 'Berhasil Login'
              })
              setTimeout(() => {
                navigate('/dashboard')
              }, 2000);

            }).catch((err) => {
              setLoading(false)
              ErrorAlert({
                message: err.response.data.message
              })
              console.log(err.response.data);
            });


        }}
      // onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="username-login">
                    Username
                  </InputLabel>
                  <OutlinedInput
                    id="username-login"
                    type="username"
                    value={values.username}
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Masukkan username anda . . ."
                    fullWidth
                    error={Boolean(touched.username && errors.username)}
                  />
                  {touched.username && errors.username && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-username-login"
                    >
                      {errors.username}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? (
                            <EyeOutlined />
                          ) : (
                            <EyeInvisibleOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password anda. . ."
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              {/* <Grid item xs={12} sx={{ mt: -1 }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="checked"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={<Typography variant="h6">Keep me sign in</Typography>}
                                    />
                                    <Link variant="h6" component={RouterLink} to="" color="text.primary">
                                        Forgot Password?
                                    </Link>
                                </Stack>
                            </Grid> */}
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              {/* <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> Login with</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
            </Grid>
            <LoadingButton fullWidth size="large" type="submit" disabled={loading} variant="contained" sx={{ marginTop: '30px' }}>
              Login
            </LoadingButton>
          </form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  );
}

export default LoginForm
