const FormatRupiah = (number) => {
    // Mengubah nilai menjadi format ribuan (3 digit)
    const formatNumber = new Intl.NumberFormat("id-ID").format(number);
    
    // Menambahkan tanda pemisah desimal
    const formattedValue = formatNumber.replace(/,/g, ".");
    
    return formattedValue;
  }

  export default FormatRupiah