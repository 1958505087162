// Toasty Alert
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';



const InfoAlert = ({ message }) => {
    toast.info(message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });
};

export default InfoAlert