import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    useMediaQuery
} from '@mui/material';

// components
import { ToastContainer } from 'react-toastify';
import MaterialTable from 'material-table';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


// sections
// import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import { listCategory, listUser } from '../../apiconfig/ApiConfig';
import CreateCategory from './CreateCategory';
import EditCategory from './EditCategory';
import DeleteCategory from './DeleteCategory';
import ExpSessionModal from '../../components/expsessionmodal/ExpSessionModal';
import TablesIcons from '../../components/tableicon/TableIcon';
import Iconify from '../../components/iconify';
import FormatRupiah from '../../components/formatrupiah/FormatRupiah';

// ----------------------------------------------------------------------

const Category = () => {

    const isNonMobile = useMediaQuery('(min-width:600px)');

    const [open, setOpen] = useState(null);
    const [dataCategory, setDataCategory] = useState([])
    const [openCategoryModal, setOpenCategoryModal] = useState(false)
    const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false)
    const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false)
    const [selectedCategoryData, setSelectedCategoryData] = useState({})

    const navigate = useNavigate()
    const [openModalExpSession, setOpenModalExpSession] = useState(false)



    const getDataCategory = () => {
        axios.get(listCategory)
            .then((response) => {
                // console.log(response.data);
                setDataCategory(response.data)
            }).catch((error) => {
                console.log(error.response);
            });
    }

    const token = localStorage.getItem("token");

    const HandleExpSession = () => {
      const expIn = localStorage.getItem("expSession");
  
      const expTime = (new Date()).getTime() > JSON.parse(expIn)
      // console.log('data storage : ', expTime);    
  
      if (expTime) {
        setOpenModalExpSession(true)
        localStorage.clear()
      }
    }
  
    useEffect(() => {
      if (token) {
        HandleExpSession()
        getDataCategory()
      } else if (!token) {
        setOpenModalExpSession(true)
        setTimeout(() => {
          navigate('/');
        }, 9000);
      }
  
    }, [token]);
  
  
    const handleCloseModal = () => {
      setOpenModalExpSession(false)
      navigate('/');
    }


    const columns =
        [
            {
                title: 'Nama Category',
                field: 'category_name',
                defaultSort: 'asc',
                // sorting: false,
                cellStyle: {
                    textAlign: 'left',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    width: '25%'
                    // border: 'solid 1px #E0E0E0',
                },

            },
            {
                title: 'Deskripsi',
                field: 'description',
                defaultSort: 'asc',
                // sorting: false,
                cellStyle: {
                    textAlign: 'left',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    // width: '2'
                    // border: 'solid 1px #E0E0E0',
                },

            },
            {
                title: 'Harga',
                field: 'total',
                render: rowData => <span style={{ fontWeight:'bold' }}>{FormatRupiah(rowData.total)}</span>,
                sorting: false,
                cellStyle: {
                    width: '50%',
                    // textAlign: 'center',
                    fontSize: '11px',
                    // padding: isNonMobile ? '20px 0px 15px 40px' : undefined,
                    // border: 'solid 1px #E0E0E0',
                },
            },
        ]

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };


    const handleActionButton = () => {
        return (
            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
        )
    }

    const handleCreateCategory = () => {
        setOpenCategoryModal(true)
    }

    return (
        <>
            <Helmet>
                <title> Category | Parkways easyticket </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h5" gutterBottom>
                        Management Category
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateCategory}>
                        Category Baru
                    </Button>
                </Stack>

                <Card sx={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)' }}>
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                    <MaterialTable
                        icons={TablesIcons}
                        columns={columns}
                        data={dataCategory}
                        localization={{
                            header: {
                                // actions: 'Aksi',
                            },
                            toolbar: {
                                // exportTitle: 'Laporan Casual',
                                exportCSVName: "Export Excel",
                                exportPDFName: "Export PDF"
                            }
                        }}
                        actions={
                            [
                                {
                                    icon: () => handleActionButton(),
                                    tooltip: 'Action Button',
                                    onClick: (e, rowData) => {
                                        setSelectedCategoryData(rowData)
                                        // console.log(rowData);
                                    },
                                },
                            ]

                        }
                        options={{
                            //   exportButton: {
                            //     csv: memberDataSwitch === 'Semua Data' ? true : false,
                            //     pdf: true,
                            //   },
                            //   exportCsv: (data, col) => exportToXlsx(),
                            //   exportPdf: () => exportToPdf(),
                            paging: true,
                            pageSize: 10, // make initial page size
                            emptyRowsWhenPaging: false, // To avoid of having empty rows
                            pageSizeOptions: [5, 10, 20, 50], // rows selection options
                            // actionsCellStyle: { textAlign: 'center', alignSelf:'center', justifyContent:'center', alignItems:'center'},
                            // search: false,
                            paginationType: 'stepped',
                            searchFieldAlignment: 'left',
                            // searchAutoFocus: true,
                            searchFieldVariant: 'outlined',
                            searchFieldStyle: {
                                margin: '20px auto 20px auto',
                            },
                            showTitle: false,
                            actionsColumnIndex: -1,
                            addRowPosition: 'first',
                            // rowStyle: (data, index) =>
                            //   index % 2 == 0 ? { background: '#f5f5f5' } : null,
                            headerStyle: {
                                fontWeight: 'bold',
                                // textAlign: 'left',
                                fontSize: '12px',
                                padding: '10px 20px 10px 15px'
                            },
                        }}
                    />
                </Card>
                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 140,
                            '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setOpenEditCategoryModal(true)
                            setOpen(false)
                        }}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setOpenDeleteCategoryModal(true)
                            setOpen(false)
                        }}
                        sx={{ color: 'error.main' }}
                    >
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                        Delete
                    </MenuItem>
                </Popover>
                <CreateCategory
                    open={openCategoryModal}
                    close={() => setOpenCategoryModal(false)}
                    getDataCategory={() => getDataCategory()}
                />
                <EditCategory
                    open={openEditCategoryModal}
                    close={() => setOpenEditCategoryModal(false)}
                    getDataCategory={() => getDataCategory()}
                    selectedCategoryData={selectedCategoryData}
                />
                <DeleteCategory
                    open={openDeleteCategoryModal}
                    close={() => setOpenDeleteCategoryModal(false)}
                    getDataCategory={() => getDataCategory()}
                    selectedUserData={selectedCategoryData}
                />
            </Container>
            <ExpSessionModal open={openModalExpSession} close={() => handleCloseModal()} />
            <ToastContainer />
        </>
    );
}

export default Category
