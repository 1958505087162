import React, { useState } from 'react'
import { Box, Card, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const CategoryTable = ({ dataCategory }) => {
    const [categories] = useState([
        { name: 'Elektronik', count: 12 },
        { name: 'Pakaian', count: 8 },
        { name: 'Makanan', count: 23 },
    ]);
    return (
        <Box>
            <Box>
                <Typography sx={{ fontSize:'16px', fontWeight:'bold', mb:'15px' }} >Daftar 10 Kategori Terlaris Hari Ini</Typography>
            </Box>
            <TableContainer component={Card} sx={{ boxShadow: 'none' }}>
                <Table aria-label="Daftar Kategori">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ backgroundColor: '#2065D1', color: '#fff', fontSize: '13px' }}>No</TableCell>
                            <TableCell sx={{ backgroundColor: '#2065D1', color: '#fff', fontSize: '13px' }}>Kategori</TableCell>
                            <TableCell align="center" sx={{ backgroundColor: '#2065D1', color: '#fff', fontSize: '13px' }}>Jumlah</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...dataCategory].sort((a, b) => b.count - a.count).map((data, i) => (
                            <TableRow key={i}>
                                <TableCell align="center" sx={{ fontSize: '12px' }}>{i + 1}</TableCell>
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', fontSize: '12px' }}>{data.category_name}</TableCell>
                                <TableCell align="center" sx={{ fontSize: '12px' }}>{data.count}</TableCell>
                            </TableRow>
                        ))}
                        {/* Menambah baris untuk mengisi sisa data */}
                        {[...Array(10 - dataCategory.length)].map((_, i) => (
                            <TableRow key={i + dataCategory.length}>
                                <TableCell align="center" sx={{ fontSize: '12px' }}>{i + dataCategory.length + 1}</TableCell>
                                <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', fontSize: '12px' }}>Tidak ada data</TableCell>
                                <TableCell align="center" sx={{ fontSize: '12px' }}>0</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    )
}

export default CategoryTable