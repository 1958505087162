
// const url = 'http://localhost:82'
// const url = 'http://192.168.8.199:8000'
// const url = 'http://192.168.1.1'
const url = 'https://apieasy.parkways.id'

// Login
export const login = `${url}/api/login`;

// Current User Login
export const userLogin = `${url}/api/user-profile`;

// Dashboard
export const todayIncome = `${url}/api/salestoday`;
export const currentMonthIncomeUrl = `${url}/api/salesmonth`;
export const sixMonthIncomeUrl = `${url}/api/monthlyincome`;
export const ticketSoldByCategory = `${url}/api/ticketSoldByCategory`;
export const ticketSold = `${url}/api/ticketSold`;
export const totalPengunjungByTime = `${url}/api/intevalTwhoHours`;
export const totalVisitorsInterval = `${url}/api/totalVisitorsInterval`;
export const totalPengunjungByWeek = `${url}/api/visitorsEveryDay`;
export const totalVisitorIn = `${url}/api/getScanned`;


// User
export const listUser = `${url}/api/Listuser`;
export const createUser = `${url}/api/CreateUser`;
export const updateUserData = `${url}/api/Users`;
export const deleteUser = `${url}/api/Users`;
export const UbahPassword = `${url}/api/updPass`;

// Location
export const getDataLocation = `${url}/api/listlocation`;
export const updateLocation = `${url}/api/updlocation`;
export const createLocation = `${url}/api/createlocation`;

// Category
export const listCategory = `${url}/api/listcategory`;
export const createCategory = `${url}/api/createcategory`;
export const updateCategory = `${url}/api/updcategory`;
export const deleteCategory = `${url}/api/delcategory`;

// Items
export const listItems = `${url}/api/listitem`;
export const createItem = `${url}/api/createitem`;
export const updateItem = `${url}/api/upditem`;
export const deleteItem = `${url}/api/delitem`;

// Member
export const listMember = `${url}/api/listMember`;
export const createMember = `${url}/api/createMember`;
export const updateMember = `${url}/api/updateMember`;
export const deleteMember = `${url}/api/delMember`;

// Report
export const listTransaction = `${url}/api/transaksi`;
export const reportTransactions = `${url}/api/reportTransactions`;
export const gateAccessReport = `${url}/api/TransactionsToGate`;
