// material-ui
import {
  Typography,
  Box,
  Button,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Card,
} from '@mui/material';

import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import axios from 'axios';
import MaterialTable, { MTableToolbar } from 'material-table';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { createMember, createUser } from '../../apiconfig/ApiConfig';
import ErrorAlert from '../../components/alert/ErrorAlert';
import SuccessAlert from '../../components/alert/SuccessAlert';
import TablesIcons from '../../components/tableicon/TableIcon';
import FormatRupiah from '../../components/formatrupiah/FormatRupiah';

const DetailTransaction = ({ open, close, detailTransactionData }) => {
  const [loading, setLoading] = useState(false);
  const isNonMobile = useMediaQuery('(min-width:600px)');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: isNonMobile ? 800 : 350,
    // bgcolor: 'background.paper',
    // borderRadius: '10px',
    boxShadow: '0px 4px 6px rgb(0, 0, 0, 0.28)',
    // p: 2,
    margin: '0px',
  };

  const columns = [
    {
      title: 'Kode Tiket',
      field: 'ticket_code',
      // defaultSort: 'asc',
      // sorting: false,
      cellStyle: {
        textAlign: 'left',
        fontSize: '11px',
        fontWeight: 'bold',
        width: '27%',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Nama Tiket',
      field: 'category_name',
      // defaultSort: 'asc',
      // sorting: false,
      cellStyle: {
        textAlign: 'left',
        fontSize: '11px',
        // fontWeight: 'bold',
        width: '33%',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Nama Item',
      field: 'item_name',
      // defaultSort: 'asc',
      // sorting: false,
      cellStyle: {
        textAlign: 'left',
        fontSize: '11px',
        // fontWeight: 'bold',
        width: '30%',
        // border: 'solid 1px #E0E0E0',
      },
    },
    {
      title: 'Harga',
      field: 'total',
      render: (rowData) => <span style={{ fontWeight: 'bold' }}>{FormatRupiah(rowData.total)}</span>,
      sorting: false,
      cellStyle: {
        width: '15%',
        // textAlign: 'center',
        fontSize: '11px',
        // padding: isNonMobile ? '20px 0px 15px 40px' : undefined,
        // border: 'solid 1px #E0E0E0',
      },
    },
  ];

  return (
    <div>
      <Modal open={open} onClose={close}>
        <Box sx={style}>
          {/* <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h4" textAlign="center">
              Rincian Transaksi
            </Typography>
          </Box> */}
          <Card
            sx={{
              boxShadow: '0px 4px 6px rgb(0, 0, 0, 0.30)',
            }}
          >
            <MaterialTable
              icons={TablesIcons}
              columns={columns}
              data={detailTransactionData.length !== 0 ? detailTransactionData : []}
              title={
                <Box sx={{width:'300px', textAlign:'center'}} >
                  <Typography sx={{fontWeight:'bold', fontSize:'18px'}} >Detail Transaksi</Typography>
                </Box>
              }
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              localization={{
                header: {
                  // actions: 'Aksi',
                },
                toolbar: {
                  // exportTitle: 'Laporan Casual',
                  exportCSVName: 'Export Excel',
                  exportPDFName: 'Export PDF',
                },
              }}
              //   actions={[
              //     {
              //       icon: () => handleActionButton(),
              //       tooltip: 'Action Button',
              //       onClick: (e, rowData) => {
              //         setSelectedCategoryData(rowData);
              //         console.log(rowData);
              //       },
              //     },
              //   ]}
              options={{
                //   exportButton: {
                //     csv: memberDataSwitch === 'Semua Data' ? true : false,
                //     pdf: true,
                //   },
                //   exportCsv: (data, col) => exportToXlsx(),
                //   exportPdf: () => exportToPdf(),
                paging: true,
                pageSize: 5, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [5], // rows selection options
                // actionsCellStyle: { textAlign: 'center', alignSelf:'center', justifyContent:'center', alignItems:'center'},
                search: false,
                paginationType: 'stepped',
                searchFieldAlignment: 'left',
                // searchAutoFocus: true,
                searchFieldVariant: 'outlined',
                searchFieldStyle: {
                  margin: '20px auto 20px auto',
                },
                showTitle: true,
                actionsColumnIndex: -1,
                addRowPosition: 'first',
                // rowStyle: (data, index) =>
                //   index % 2 == 0 ? { background: '#f5f5f5' } : null,
                headerStyle: {
                  fontWeight: 'bold',
                  // textAlign: 'left',
                  fontSize: '12px',
                  padding: '10px 20px 10px 15px',
                },
              }}
            />
          </Card>
          <Box
            sx={{
              // marginTop: '20px',
              display: 'flex',
              justifyContent: 'end',
              // marginRight: '10px',
              // marginTop: '30px',
              // marginBottom: '5px',
            }}
          >
            {/* <Button
                // onClick={handleBackButton}
                color="error"
                variant="outlined"
                onClick={() => {
                  close();
                }}
                sx={{
                  marginRight: '15px',
                  width: '70px',
                  fontSize: '13px',
                }}
                disabled={loading}
              >
                Kembali
              </Button> */}
            {/* <LoadingButton
              type="submit"
              size="medium"
              loading={loading}
              loadingIndicator="Loading…"
              variant="contained"
              onClick={close}
            >
              Kembali
            </LoadingButton> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DetailTransaction;
