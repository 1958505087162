import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Box,
} from '@mui/material';
import Countdown from '../countdown/Countdown';

const ExpSessionModal = ({ open, close }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isNonMobile = useMediaQuery('(min-width:600px)');

    return (
        <Dialog
            // fullScreen={fullScreen}
            open={open}
            onClose={close}
        >
            <DialogTitle id="responsive-dialog-title" sx={{ fontWeight: 'bold' }}>
                {"Login Kadarluarsa"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ fontWeight: 'bold' }}>
                    Sesi login Anda telah kadarluarsa, silahkan login kembali.
                </DialogContentText>
            </DialogContent>
            <Box display='flex' alignItems='center' sx={{ padding: isNonMobile ? '5px' : '3px', marginTop: isNonMobile ? '-15px' : '-20px' }} >
                <Box width='50%' justifyContent='start' ml='20px'>
                    <Stack sx={{ color: 'grey.500' }} spacing={2}  direction="row">
                        <CircularProgress color="primary" size='20px' />
                        <span style={{fontSize:'10px', color:'black', marginTop:'3px'}}>
                            Otomatis diarahkan dalam <span style={{marginLeft:'3px'}}><Countdown time={8} /></span> 
                        </span>
                    </Stack>
                </Box>
                <Box width='50%' justifyContent='end'>
                    <DialogActions >
                        <Button onClick={close} variant='contained' size='medium'>
                            Login
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        </Dialog>
    );
}

export default ExpSessionModal;