import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';

// components
import { ToastContainer } from 'react-toastify';
import MaterialTable from 'material-table';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/iconify';


// sections
// import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';
import TablesIcons from '../../components/tableicon/TableIcon';
import { listCategory, listItems, listUser } from '../../apiconfig/ApiConfig';
import CreateItem from './Createitem';
import EditItem from './EditItem';
import DeleteItem from './DeleteItem';
import ExpSessionModal from '../../components/expsessionmodal/ExpSessionModal';
import FormatRupiah from '../../components/formatrupiah/FormatRupiah';

// ----------------------------------------------------------------------

const Items = () => {

    const [open, setOpen] = useState(null);
    const [dataItems, setDataItems] = useState([])
    const [dataCategory, setDataCategory] = useState([])
    const [openItemModal, setOpenItemModal] = useState(false)
    const [openEditItemModal, setOpenEditItemModal] = useState(false)
    const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false)
    const [selectedItemData, setSelectedItemData] = useState({})

    const navigate = useNavigate()
    const [openModalExpSession, setOpenModalExpSession] = useState(false)



    const getDataItems = () => {
        axios.post(listItems)
            .then((response) => {
                // console.log(response.data);
                setDataItems(response.data.item)
            }).catch((error) => {
                console.log(error.response);
            });
    }

    const getDataCategory = () => {
        axios.get(listCategory)
            .then((response) => {
                // console.log(response.data);
                setDataCategory(response.data)
            }).catch((error) => {
                console.log(error.response);
            });
    }

    const token = localStorage.getItem("token");

    const HandleExpSession = () => {
        const expIn = localStorage.getItem("expSession");

        const expTime = (new Date()).getTime() > JSON.parse(expIn)
        // console.log('data storage : ', expTime);    

        if (expTime) {
            setOpenModalExpSession(true)
            localStorage.clear()
        }
    }

    useEffect(() => {
        if (token) {
            HandleExpSession()
            getDataItems()
            getDataCategory()
        } else if (!token) {
            setOpenModalExpSession(true)
            setTimeout(() => {
                navigate('/');
            }, 9000);
        }

    }, [token]);


    const handleCloseModal = () => {
        setOpenModalExpSession(false)
        navigate('/');
    }


    const columns =
        [
            {
                title: 'Nama Category',
                field: 'category_name',
                defaultSort: 'asc',
                // sorting: false,
                cellStyle: {
                    textAlign: 'left',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    width: '25%'
                    // border: 'solid 1px #E0E0E0',
                },

            },
            {
                title: 'Nama Item',
                field: 'item_name',
                // render: rowData => rowData.total,
                sorting: false,
                cellStyle: {
                    width: '20%',
                    // textAlign: 'center',
                    fontSize: '11px',
                    // border: 'solid 1px #E0E0E0',
                },
            },
            {
                title: 'Harga',
                field: 'price',
                render: rowData => <span style={{ fontWeight:'bold' }} >{FormatRupiah(rowData.price)}</span>,
                sorting: false,
                cellStyle: {
                    width: '55%',
                    // textAlign: 'center',
                    fontSize: '11px',
                    // border: 'solid 1px #E0E0E0',
                },
            },
        ]

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };


    const handleActionButton = () => {
        return (
            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                <Iconify icon={'eva:more-vertical-fill'} />
            </IconButton>
        )
    }

    const handleCreateItem = () => {
        setOpenItemModal(true)
    }

    return (
        <>
            <Helmet>
                <title> Items | Parkways easyticket </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h5" gutterBottom>
                        Management Items
                    </Typography>
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleCreateItem}>
                        Item Baru
                    </Button>
                </Stack>

                <Card sx={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)' }}>
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                    <MaterialTable
                        icons={TablesIcons}
                        columns={columns}
                        data={dataItems}
                        localization={{
                            header: {
                                // actions: 'Aksi',
                            },
                            toolbar: {
                                // exportTitle: 'Laporan Casual',
                                exportCSVName: "Export Excel",
                                exportPDFName: "Export PDF"
                            }
                        }}
                        actions={
                            [
                                {
                                    icon: () => handleActionButton(),
                                    tooltip: 'Action Button',
                                    onClick: (e, rowData) => {
                                        setSelectedItemData(rowData)
                                        // console.log(rowData);
                                    },
                                },
                            ]

                        }
                        options={{
                            //   exportButton: {
                            //     csv: memberDataSwitch === 'Semua Data' ? true : false,
                            //     pdf: true,
                            //   },
                            //   exportCsv: (data, col) => exportToXlsx(),
                            //   exportPdf: () => exportToPdf(),
                            paging: true,
                            pageSize: 10, // make initial page size
                            emptyRowsWhenPaging: false, // To avoid of having empty rows
                            pageSizeOptions: [5, 10, 20, 50], // rows selection options
                            // actionsCellStyle: { textAlign: 'center', alignSelf:'center', justifyContent:'center', alignItems:'center'},
                            // search: false,
                            paginationType: 'stepped',
                            searchFieldAlignment: 'left',
                            // searchAutoFocus: true,
                            searchFieldVariant: 'outlined',
                            searchFieldStyle: {
                                margin: '20px auto 20px auto',
                            },
                            showTitle: false,
                            actionsColumnIndex: -1,
                            addRowPosition: 'first',
                            // rowStyle: (data, index) =>
                            //   index % 2 == 0 ? { background: '#f5f5f5' } : null,
                            headerStyle: {
                                fontWeight: 'bold',
                                // textAlign: 'left',
                                fontSize: '12px',
                                padding: '10px 20px 10px 15px'
                            },
                        }}
                    />
                </Card>
                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 140,
                            '& .MuiMenuItem-root': {
                                px: 1,
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            setOpenEditItemModal(true)
                            setOpen(false)
                        }}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setOpenDeleteItemModal(true)
                            setOpen(false)
                        }}
                        sx={{ color: 'error.main' }}
                    >
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                        Delete
                    </MenuItem>
                </Popover>
                <CreateItem
                    open={openItemModal}
                    close={() => setOpenItemModal(false)}
                    getDataItem={() => getDataItems()}
                    dataCategory={dataCategory}
                />
                <EditItem
                    open={openEditItemModal}
                    close={() => setOpenEditItemModal(false)}
                    getDataItem={() => getDataItems()}
                    dataCategory={dataCategory}
                    selectedData={selectedItemData}
                />
                <DeleteItem
                    open={openDeleteItemModal}
                    close={() => setOpenDeleteItemModal(false)}
                    getDataItem={() => getDataItems()}
                    selectedData={selectedItemData}
                />
            </Container>
            <ExpSessionModal open={openModalExpSession} close={() => handleCloseModal()} />
            <ToastContainer />
        </>
    );
}

export default Items
