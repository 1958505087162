import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, Stack, Button } from '@mui/material';


import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { currentMonthIncomeUrl, sixMonthIncomeUrl, ticketSold, ticketSoldByCategory, todayIncome, totalPengunjungByTime, totalPengunjungByWeek, totalVisitorIn, totalVisitorsInterval, userLogin } from '../../apiconfig/ApiConfig';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';
// components
import Iconify from '../../components/iconify';
import CapitalizeFirstLetter from '../../components/capitalizefirstletter/CapitalizeFirstLetter';
import CategoryTable from './CategoryTable';
import ExpSessionModal from '../../components/expsessionmodal/ExpSessionModal';

// ----------------------------------------------------------------------

const Dashboard = () => {
  const theme = useTheme();


  const [IncomeToday, setIncomeToday] = useState([])
  const [currentMonthIncome, setCurrentMonthIncome] = useState('')
  const [currentUser, setCurrentUser] = useState([])
  const [sixMonthIncome, setSixMonthIncome] = useState([])
  const [sixMonthIncomeAll, setSixMonthIncomeAll] = useState([])
  const [sixMonthLabel, setSixMonthLabel] = useState([])
  const [dataTicketSoldByCategory, setDataTicketSoldByCategory] = useState([])
  const [totalTiketSold, setTotalTiketSold] = useState({})
  const [dataTotalPengunjungByTime, setDataTotalPengunjungByTime] = useState([])
  const [datatotalPengunjungByWeek, setDatatotalPengunjungByWeek] = useState([])
  const [dataTotalVisitorIn, setDataTotalVisitorIn] = useState()
  const [slot, setSlot] = useState('Hari ini')
  const navigate = useNavigate()
  const [openModalExpSession, setOpenModalExpSession] = useState(false)

  const getTodayIncome = async () => {

    await axios.post(todayIncome)
      .then((response) => {
        // console.log('today income : ', response.data);
        setIncomeToday(response.data)
      }).catch((err) => {
        console.log(err.response.data);
      });

  }

  const getCurrentMonthIncome = async () => {

    await axios.post(currentMonthIncomeUrl)
      .then((response) => {
        // console.log('current month income : ', response.data);
        setCurrentMonthIncome(response.data)
      }).catch((err) => {
        console.log(err.response.data);
      });

  }

  const getSixMonthIncome = async () => {
    await axios.post(sixMonthIncomeUrl)
      .then((response) => {
        // console.log('six month incomess : ', response.data);
        const uniqueIncomes = [];
        const uniqueLabels = [];

        // Loop through the data and check for duplicates
        response.data.forEach((item) => {
          const { sales, month } = item
          uniqueIncomes.push(sales);

          uniqueLabels.push(month);

        });

        // Update state with unique values
        setSixMonthIncome(uniqueIncomes);
        setSixMonthLabel(uniqueLabels);
        setSixMonthIncomeAll(response.data)


        // console.log('tess111', uniqueIncomes, uniqueLabels);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getUserData = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    await axios.get(userLogin)
      .then((response) => {
        setCurrentUser(response.data);
      }).catch((error) => {
        console.log(error.response);
      })
  }

  const getTicketSoldByCategory = async () => {
    await axios.post(ticketSoldByCategory)
      .then((response) => {

        // console.log('tiketsoldbycategory', response.data);

        setDataTicketSoldByCategory(response.data);
      }).catch((error) => {
        console.log(error.response);
      })
  }


  const getTotalTicketSold = async () => {
    await axios.post(ticketSold)
      .then((response) => {
        // console.log('total tiket sold', response.data);
        setTotalTiketSold(response.data);
      }).catch((error) => {
        console.log(error.response);
      })
  }

  const getTotalPengunjungByTime = async () => {
    await axios.post(totalVisitorsInterval)
      .then((response) => {
        // console.log('total pengunjungbytime', response.data);
        setDataTotalPengunjungByTime(response.data);
      }).catch((error) => {
        console.log(error.response);
      })
  }
  
  const getTotalPengunjungByDay = async () => {
    await axios.post(totalPengunjungByWeek)
      .then((response) => {
        // console.log('total pengunjung by week', response.data);
        setDatatotalPengunjungByWeek(response.data);
      }).catch((error) => {
        console.log(error.response);
      })
  }

  const getTotalVisitorIn = async () => {
    await axios.post(totalVisitorIn).then((response) => {
      // console.log('total visitor in', response.data.count);
      setDataTotalVisitorIn(response.data.count)
    }).catch((error) => {
      console.log(error.response.data);
    })
  }

  const HandleExpSession = () => {
    const expIn = localStorage.getItem("expSession");

    const expTime = (new Date()).getTime() > JSON.parse(expIn)
    // console.log('data storage : ', expTime);    

    if (expTime) {
      setOpenModalExpSession(true)
      localStorage.clear()
    }
  }

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      HandleExpSession()
      getTodayIncome()
      getCurrentMonthIncome()
      getSixMonthIncome()
      getUserData()
      getTicketSoldByCategory()
      getTotalTicketSold()
      getTotalPengunjungByTime()
      getTotalPengunjungByDay()
      getTotalVisitorIn()
    } else if (!token) {
      setOpenModalExpSession(true)
      setTimeout(() => {
        navigate('/');
      }, 9000);
    }

  }, [token]);


  const handleCloseModal = () => {
    setOpenModalExpSession(false)
    navigate('/');
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | Parkways easyticket </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mb: 5 }}>
          Hai {CapitalizeFirstLetter(currentUser.username)}, Selamat Datang Kembali.
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              titleStyle={1}
              title="Pendapatan bulan ini"
              description={currentMonthIncome.currentmonthsales > currentMonthIncome.lastmonthsales ? 'Meningkat' : 'Menurun'}
              data1={currentMonthIncome.currentmonthsales}
              data2={currentMonthIncome.lastmonthsales}
              percentage={currentMonthIncome.PercentageDifference}
              currentMonthIncomeDataArray={currentMonthIncome}
              descriptionTrigger={Boolean(true)}
              total={currentMonthIncome.currentmonthsales}
              color="success"
              icon={'emojione:money-bag'} 
              width={38}
              height={38}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              titleStyle={1}
              title="Pendapatan hari ini"
              description={IncomeToday.total_sales_today > IncomeToday.total_sales_yesterday ? 'Meningkat' : 'Menurun'}
              data1={IncomeToday.total_sales_today}
              data2={IncomeToday.total_sales_yesterday}
              percentage={IncomeToday.percentageDifference}
              todayIncomeDataArray={IncomeToday}
              descriptionTrigger={Boolean(true)}
              total={IncomeToday.total_sales_today}
              icon={'streamline-emojis:money-bag'}
              width={40}
              height={40}
            // color="success" 
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Jumlah pengunjung hari ini"
              total={totalTiketSold.data}
              color="warning"
              icon={'streamline-emojis:family-man-man-girl-boy-2'}
              width={38}
              height={38}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              titleStyle={2}
              title="Sudah Masuk"
              title2="Belum Masuk"
              total2={totalTiketSold.pending}
              totalVisitorIn={dataTotalVisitorIn}
              color="error"
              icon={'emojione-v1:admission-tickets'}
              width={50}
              height={50}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Pendapatan 6 bulan terakhir"
              //   subheader="(+43%) than last year"
              chartLabels={sixMonthLabel}
              data={sixMonthIncomeAll}
              chartData={[
                // {
                //   name: 'Team A',
                //   type: 'column',
                //   fill: 'solid',
                //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                // },
                // {
                //   name: 'Team B',
                //   type: 'area',
                //   fill: 'gradient',
                //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                // },
                {
                  name: 'Pendapatan',
                  fill: 'solid',
                  data: sixMonthIncome,
                },
              ]}
            />
          </Grid>



          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Persentase Kategori"
              chartData={dataTicketSoldByCategory}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>


          <Grid item xs={12} md={6} lg={8}>
            <Stack direction="row" alignItems="center" spacing={2} mb='10px'>
              <Button
                size="small"
                onClick={() => setSlot('Hari ini')}
                color={slot === 'Hari ini' ? 'primary' : 'secondary'}
                variant={slot === 'Hari ini' ? 'contained' : 'outlined'}
              >
                Hari ini
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('Minggu ini')}
                color={slot === 'Minggu ini' ? 'primary' : 'secondary'}
                variant={slot === 'Minggu ini' ? 'contained' : 'outlined'}
              >
                Minggu ini
              </Button>
            </Stack>
            <AppConversionRates
              title={slot === 'Hari ini' ? "Total pengunjung hari ini" : "Total pengunjung minggu ini"}
              // subheader="(+43%) than last year"
              switchData={slot}
              chartData={
                slot === 'Hari ini' ?
                  dataTotalPengunjungByTime
                  :
                  datatotalPengunjungByWeek
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <CategoryTable dataCategory={dataTicketSoldByCategory} />
          </Grid>



          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
        <ExpSessionModal open={openModalExpSession} close={() => handleCloseModal()} />
        <ToastContainer />
      </Container>
    </>
  );
}

export default Dashboard