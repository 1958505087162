import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import * as XLSX from 'xlsx';
// @mui
import { Card, CardHeader, Box, MenuItem, Popover, IconButton } from '@mui/material';
// components
import { Tooltip } from '@material-ui/core';
import Iconify from '../../../components/iconify/Iconify';
import { useChart } from '../../../components/chart';
import FormatRupiah from '../../../components/formatrupiah/FormatRupiah';
import LogoParkways from '../../../assets/logo/logo-parkways.png';
import WebIcon from '../../../assets/logo/web.png';
import InfoAlert from '../../../components/alert/InfoAlert';
import ErrorAlert from '../../../components/alert/ErrorAlert';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels, chartData, data, ...other }) {
  const [open, setOpen] = useState(null);

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    // xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${FormatRupiah(y.toFixed(0))}`;
          }
          return y;
        },
      },
    },
  });

  // console.log('tessss', data);

  const handleActionButton = () => {
    return (
      <Tooltip title='Download'>
        <IconButton size="large" color="inherit" onClick={handleOpenMenu} sx={{ mr: '10px' }}>
          <Iconify icon={'prime:download'} width={25} />
        </IconButton>
      </Tooltip>
    );
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // const handleExportPdf = () => {
  //   alert('PDF')
  // }

  // Fungsi untuk export data ke format file CSV
  const handleExportExcel = () => {
    if (data.length !== 0) {
      // Membuat objek custom header
      const exportData = data.map((item) => ({
        'Bulan & Tahun': item.month,
        Total: FormatRupiah(item.sales),
      }));

      const workSheet = XLSX.utils.json_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'DataPendapatan6BulanTerakhir');

      XLSX.writeFile(workBook, 'DataPendapatan6BulanTerakhir.xlsx');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
    handleCloseMenu()
  };



  // Fungsi untuk export data to PDF
  const handleExportPdf = () => {
    const doc = new JsPDF();

    // Mendefinisikan logo
    const logoUrl = LogoParkways;
    const logoWidth = 16;
    const logoHeight = 16;

    const columns = [
      {
        title: 'Bulan & Tahun',
        field: 'month',
      },
      {
        title: 'Total',
        field: 'sales',
      },
    ];

    if (data.length !== 0) {
      const titleX = doc.internal.pageSize.getWidth() / 15.5; // Posisi horizontal (x-coordinate) di tengah halaman
      const titleY = 25; // Posisi vertikal (y-coordinate)

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text('Pendapatan 6 Bulan Terakhir', titleX, titleY);

      // Menambahkan logo
      doc.addImage(logoUrl, 'PNG', 155, 10, logoWidth, logoHeight);
      doc.setTextColor('#1E458C');
      doc.text('Parkways', 170, 18);
      doc.setFontSize(5);
      doc.text('Digital Parking Transformation', 170, 21.5);

      doc.setTextColor('black');
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(7);

      // Menggambar garis bawah pada judul
      doc.setLineWidth(0.1);
      doc.line(13, titleY + 2.5, doc.internal.pageSize.getWidth() - 13, titleY + 2);

      const startY = 42;

      let grandTotal = 0;

      const rows = data.map((data) => {
        const sales = data.sales ? parseInt(data.sales, 10) : 0;
        grandTotal += sales;

        return {
          month: data.month,
          sales: FormatRupiah(data.sales),
        };
      });

      // Tambahkan Grand Total ke array rows
      rows.push({
        month: 'Grand Total',
        sales: FormatRupiah(grandTotal),
      });

      doc.autoTable({
        startY,
        showHead: 'firstPage',
        rowPageBreak: 'avoid',
        headStyles: {
          valign: 'middle',
          halign: 'left',
          fontSize: 9,
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        columnStyles: {
          month: {
            halign: 'middle',
            valign: 'middle',
            cellWidth: 153,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
          sales: {
            halign: 'right',
            valign: 'middle',
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
        },

        columns: columns.map((col) => ({
          header: col.title,
          dataKey: col.field,
        })),
        body: rows,
      });

      // Footer Section
      // Mendapatkan tanggal saat ini
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      const currentTime = moment(new Date()).format('HH:mm:ss');

      // Mendefinisikan ikon web
      const webIconUrl = WebIcon;
      const webIconWidth = 4;
      const webIconHeight = 4;

      // Menambahkan ikon web di sebelah kiri www.bssparking.com
      const webIconX1 = 127; // Koordinat X untuk ikon web
      const webIconY = doc.internal.pageSize.getHeight() - 13;

      // Memuat ikon web
      doc.addImage(webIconUrl, 'PNG', webIconX1, webIconY, webIconWidth, webIconHeight);

      // Mendefinisikan teks footer
      const leftFooterText = `Dicetak pada ${currentDate} ${currentTime}`;
      const rightFooterText1 = 'www.parkways.id';
      const rightFooterText2 = 'www.bssparking.com';

      // Menambahkan footer
      doc.setFontSize(8);
      doc.setTextColor('gray');

      // Teks footer di sebelah kiri
      doc.text(leftFooterText, 8 + webIconWidth + 2, doc.internal.pageSize.getHeight() - 10);
      doc.setFont('helvetica', 'bold');

      // Teks footer di sebelah kanan www.parkways.id
      const rightFooterTextWidth1 =
        (doc.getStringUnitWidth(rightFooterText1) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX1 = doc.internal.pageSize.getWidth() - rightFooterTextWidth1 - 13;
      doc.text(rightFooterText1, rightFooterTextX1, doc.internal.pageSize.getHeight() - 10);

      // Menambahkan ikon web
      const webIconX2 = rightFooterTextX1 - webIconWidth - 2;
      doc.addImage(webIconUrl, 'PNG', webIconX2, webIconY, webIconWidth, webIconHeight);

      // Teks footer di sebelah kanan www.bssparking.com
      const rightFooterTextWidth2 =
        (doc.getStringUnitWidth(rightFooterText2) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX2 = webIconX2 - rightFooterTextWidth2 - 6;
      doc.text(rightFooterText2, rightFooterTextX2, doc.internal.pageSize.getHeight() - 10);

      doc.save('DataPendapatan6BulanTerakhir.pdf');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
    handleCloseMenu()
  };

  return (
    <Card {...other}>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <CardHeader title={title} subheader={subheader} />
        {handleActionButton()}
      </Box>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleExportExcel();
          }}
        >
          <Iconify icon={'vscode-icons:file-type-excel'} width={25} sx={{ mr: 2 }} />
          Excel
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleExportPdf();
          }}
        >
          <Iconify icon={'vscode-icons:file-type-pdf2'} width={25} sx={{ mr: 2 }} />
          Pdf
        </MenuItem>
      </Popover>
    </Card>
  );
}
