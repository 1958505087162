import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import * as XLSX from 'xlsx';

// @mui
import { Box, Card, CardHeader, IconButton, MenuItem, Popover } from '@mui/material';
import { Tooltip } from '@material-ui/core';
// utils
import FormatRupiah from '../../../components/formatrupiah/FormatRupiah';
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';
import Iconify from '../../../components/iconify/Iconify';
import InfoAlert from '../../../components/alert/InfoAlert';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import LogoParkways from '../../../assets/logo/logo-parkways.png';
import WebIcon from '../../../assets/logo/web.png';

// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

export default function AppConversionRates({ title, subheader, chartData, switchData, ...other }) {
  const chartLabels = chartData.map((i) =>
    switchData === 'Hari ini' ? `${i.interval_time.slice(0, 5)} — ${i.interval_time.slice(10, 16)}` : i.interval_day
  );
  const chartSeries = chartData.map((i) => i.visitor_count);

  const [open, setOpen] = useState(null);

  const chartOptions = useChart({
    tooltip: {
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => 'Total : ',
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '40%', borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  // console.log(chartData);

  const handleActionButton = () => {
    return (
      <Tooltip title='Download'>
        <IconButton size="large" color="inherit" onClick={handleOpenMenu} sx={{ mr: '10px' }}>
          <Iconify icon={'prime:download'} width={25} />
        </IconButton>
      </Tooltip>
    );
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleExportExcel = () => {
    if (chartData.length !== 0) {
      if (switchData === 'Hari ini') {
        // Membuat objek custom header
        const exportData = chartData.map((item) => ({
          'Waktu': item.interval_time,
          Total: item.visitor_count,
        }))

        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'DataPengunjungHariIni');

        XLSX.writeFile(workBook, 'DataPengunjungHariIni.xlsx');
        InfoAlert({
          message: 'Tersimpan di folder download',
        });
      } else {
        // Membuat objek custom header
        const exportData = chartData.map((item) => ({
          'Hari': item.interval_day,
          Total: item.visitor_count,
        }));

        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'DataPengunjungMingguIni');

        XLSX.writeFile(workBook, 'DataPengunjungMingguIni.xlsx');
        InfoAlert({
          message: 'Tersimpan di folder download',
        });
      }
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
    handleCloseMenu()
  };

  const handleExportPdf = () => {
    const doc = new JsPDF();

    // Mendefinisikan logo
    const logoUrl = LogoParkways;
    const logoWidth = 16;
    const logoHeight = 16;

    const columnsToday = [
      {
        title: 'Waktu',
        field: 'interval_time',
      },
      {
        title: 'Total Pengunjung',
        field: 'visitor_count',
      },
    ];

    const columnsThisWeek = [
      {
        title: 'Hari',
        field: 'interval_day',
      },
      {
        title: 'Total Pengunjung',
        field: 'visitor_count',
      },
    ];

    if (chartData.length !== 0) {
      const titleX = doc.internal.pageSize.getWidth() / 15.5; // Posisi horizontal (x-coordinate) di tengah halaman
      const titleY = 25; // Posisi vertikal (y-coordinate)

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text('Pendapatan 6 Bulan Terakhir', titleX, titleY);

      // Menambahkan logo
      doc.addImage(logoUrl, 'PNG', 155, 10, logoWidth, logoHeight);
      doc.setTextColor('#1E458C');
      doc.text('Parkways', 170, 18);
      doc.setFontSize(5);
      doc.text('Digital Parking Transformation', 170, 21.5);

      doc.setTextColor('black');
      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(7);

      // Menggambar garis bawah pada judul
      doc.setLineWidth(0.1);
      doc.line(13, titleY + 2.5, doc.internal.pageSize.getWidth() - 13, titleY + 2);

      const startY = 42;

      let grandTotal = 0;

      const rows = chartData.map((data) => {
        const visitorCount = data.visitor_count ? parseInt(data.visitor_count, 10) : 0;
        grandTotal += visitorCount;

        if (switchData === 'Hari ini') {
          return {
            interval_time: data.interval_time,
            visitor_count: FormatRupiah(data.visitor_count),
          };
        }
        return {
          interval_day: data.interval_day,
          visitor_count: FormatRupiah(data.visitor_count),
        };

      });

      console.log('grandtotal', grandTotal);
      // Tambahkan Grand Total ke array rows
      if (switchData === 'Hari ini') {
        rows.push({
          interval_time: 'Grand Total',
          visitor_count: FormatRupiah(grandTotal),
        });
      } else {
        rows.push({
          interval_day: 'Grand Total',
          visitor_count: FormatRupiah(grandTotal),
        });
      }


      doc.autoTable({
        startY,
        showHead: 'firstPage',
        rowPageBreak: 'avoid',
        headStyles: {
          valign: 'middle',
          halign: 'left',
          fontSize: 9,
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        columnStyles: {
          interval_day: {
            halign: 'middle',
            valign: 'middle',
            cellWidth: 143,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
          interval_time: {
            halign: 'middle',
            valign: 'middle',
            cellWidth: 143,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
          visitor_count: {
            halign: 'right',
            valign: 'middle',
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            // fontStyle: 'bold',
          },
          grandTotal: {
            halign: 'right',
            valign: 'middle',
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            // fontStyle: 'bold',
          },
        },

        columns: switchData === 'Hari ini' ?
          columnsToday.map((col) => ({
            header: col.title,
            dataKey: col.field,
          }))
          :
          columnsThisWeek.map((col) => ({
            header: col.title,
            dataKey: col.field,
          })),

        // body: switchData === 'Hari ini' ?
        //   chartData.map(data => ({
        //     interval_time: data.interval_time,
        //     visitor_count: data.visitor_count
        //   }))
        //   :
        //   chartData.map(data => ({
        //     interval_day: data.interval_day,
        //     visitor_count: data.visitor_count
        //   }))

        body: rows
      });

      // Footer Section
      // Mendapatkan tanggal saat ini
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      const currentTime = moment(new Date()).format('HH:mm:ss');

      // Mendefinisikan ikon web
      const webIconUrl = WebIcon;
      const webIconWidth = 4;
      const webIconHeight = 4;

      // Menambahkan ikon web di sebelah kiri www.bssparking.com
      const webIconX1 = 127; // Koordinat X untuk ikon web
      const webIconY = doc.internal.pageSize.getHeight() - 13;

      // Memuat ikon web
      doc.addImage(webIconUrl, 'PNG', webIconX1, webIconY, webIconWidth, webIconHeight);

      // Mendefinisikan teks footer
      const leftFooterText = `Dicetak pada ${currentDate} ${currentTime}`;
      const rightFooterText1 = 'www.parkways.id';
      const rightFooterText2 = 'www.bssparking.com';

      // Menambahkan footer
      doc.setFontSize(8);
      doc.setTextColor('gray');

      // Teks footer di sebelah kiri
      doc.text(leftFooterText, 8 + webIconWidth + 2, doc.internal.pageSize.getHeight() - 10);
      doc.setFont('helvetica', 'bold');

      // Teks footer di sebelah kanan www.parkways.id
      const rightFooterTextWidth1 =
        (doc.getStringUnitWidth(rightFooterText1) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX1 = doc.internal.pageSize.getWidth() - rightFooterTextWidth1 - 13;
      doc.text(rightFooterText1, rightFooterTextX1, doc.internal.pageSize.getHeight() - 10);

      // Menambahkan ikon web
      const webIconX2 = rightFooterTextX1 - webIconWidth - 2;
      doc.addImage(webIconUrl, 'PNG', webIconX2, webIconY, webIconWidth, webIconHeight);

      // Teks footer di sebelah kanan www.bssparking.com
      const rightFooterTextWidth2 =
        (doc.getStringUnitWidth(rightFooterText2) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX2 = webIconX2 - rightFooterTextWidth2 - 6;
      doc.text(rightFooterText2, rightFooterTextX2, doc.internal.pageSize.getHeight() - 10);

      doc.save(switchData === 'Hari ini' ? 'DataHariIni.pdf' : 'DataMingguIni.pdf');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
    handleCloseMenu()
  };

  console.log(chartData);
  return (
    <Card {...other}>
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <CardHeader title={title} subheader={subheader} />
        {handleActionButton()}
      </Box>

      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
      </Box>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleExportExcel();
          }}
        >
          <Iconify icon={'vscode-icons:file-type-excel'} width={25} sx={{ mr: 2 }} />
          Excel
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleExportPdf();
          }}
        >
          <Iconify icon={'vscode-icons:file-type-pdf2'} width={25} sx={{ mr: 2 }} />
          Pdf
        </MenuItem>
      </Popover>
    </Card>
  );
}
