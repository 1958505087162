// material-ui
import {
  Typography,
  Box,
  Button,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from '@mui/material';

import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import axios from 'axios';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { createMember, createUser } from '../../apiconfig/ApiConfig';
import ErrorAlert from '../../components/alert/ErrorAlert';
import SuccessAlert from '../../components/alert/SuccessAlert';

const CreateMember = ({ open, close, getDataMember }) => {
  const isNonMobile = useMediaQuery('(min-width:600px)');

  // const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [newData, setNewData] = useState({
    member_name: '',
    member_code: '',
    phone: '',
  });
  const [timeLimit, setTimeLimit] = useState(new Date())

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isNonMobile ? 400 : 360,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // console.log(newData.member_name);
    // console.log(newData.member_code);
    // console.log(newData.phone);
    // console.log(moment(timeLimit).format('YYYY-MM-DD'));

    await axios.post(createMember, {
        name: newData.member_name,
        member_code: newData.member_code,
        phone: newData.phone,
        time_limit: moment(timeLimit).format('YYYY-MM-DD'),
    }).then((response) => {
        // console.log(response.data);
        clearFormData()
        close()
        setLoading(false)
        getDataMember()
        SuccessAlert({
            message: 'Berhasil Menambah Member'
        })
    }).catch((err) => {
        console.log(err.response.data);
        ErrorAlert({
            message: err.response.data.warning
        })
        setLoading(false)
    });
  };

  // Fungsi untuk menyimpan value yang diinput ke variabel newdata
  const handleChange = (e, props) => {
    const { name, value } = e.target;
    setNewData((prev) => {
      return { ...prev, [name]: value };
    });
    // userId = props.id
    // console.log([name], value);
    // dispatch(getDataStaff(newData))
  };

  const clearFormData = () => {
    setNewData({
      member_name: '',
      member_code: '',
      phone: '',
      // time_limit: '',
    });
  };

  return (
    <div>
      <Modal open={open}>
        <Box sx={style}>
          <form onSubmit={(e) => handleSubmit(e)}>
            {/* <Box display="flex" justifyContent="end">
                  <Tooltip title="Tutup">
                    <IconButton onClick={close}>
                      <CloseCircleOutlined
                        style={{ fontSize: '20px', color: 'red' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box> */}
            <Box mb="35px" display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h4" textAlign="center">
                Membuat Member Baru
              </Typography>
            </Box>
            <Box
              justifyContent="center"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              // p="10px 10px"
              sx={{
                '& > div': {
                  gridColumn: isNonMobile ? undefined : 'span 4',
                },
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Nama Member"
                placeholder="giovr"
                value={newData.member_name}
                onChange={(e) => handleChange(e)}
                name="member_name"
                autoComplete="off"
                required
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Kode Member"
                // placeholder="giovr"
                value={newData.member_code}
                onChange={(e) => handleChange(e)}
                name="member_code"
                autoComplete="off"
                required
                sx={{ gridColumn: 'span 4' }}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="No Telepon"
                // placeholder="giovr"
                value={newData.phone}
                onChange={(e) => handleChange(e)}
                name="phone"
                autoComplete="off"
                required
                sx={{ gridColumn: 'span 4' }}
              />
              <DatePicker
                label="Masa Berlaku"
                name='time_limit'
                // localeText={{ toolbarTitle: 'Title' }}
                sx={{ gridColumn: 'span 4', marginTop:'2px' }}
                slotProps={{
                  textField: { variant: 'outlined' },
                  toolbar: {
                    toolbarPlaceholder: '__',
                    toolbarFormat: 'yyyy-mm-dd',
                    hidden: true,
                  },
                }}
                // slots={{
                //   toolbar: CustomToolbarComponent
                // }}
                value={timeLimit}
                onChange={(e) => setTimeLimit(e)}
              />
            </Box>
            <Box
              sx={{
                // marginTop: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                // marginRight: '10px',
                marginTop: '50px',
                marginBottom: '5px',
              }}
            >
              <Button
                // onClick={handleBackButton}
                color="error"
                variant="outlined"
                onClick={() => {
                  close();
                  clearFormData();
                }}
                sx={{
                  marginRight: '15px',
                  width: '70px',
                  fontSize: '13px',
                }}
                disabled={loading}
              >
                Kembali
              </Button>
              <LoadingButton
                type="submit"
                size="medium"
                loading={loading}
                loadingIndicator="Loading…"
                variant="contained"
              >
                Buat User
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateMember;
