// material-ui
import {
    Typography,
    Box,
    Button,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    TextField,
    OutlinedInput,
    IconButton,
    InputAdornment,
} from '@mui/material';

import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { createCategory, createLocation, createUser } from '../../apiconfig/ApiConfig';
import ErrorAlert from '../../components/alert/ErrorAlert';
import SuccessAlert from '../../components/alert/SuccessAlert';
import Header from '../../layouts/dashboard/header/index';


const CreateLocation = ({
    open,
    close,
    getLocationData,
}) => {
    const isNonMobile = useMediaQuery('(min-width:600px)');

    // const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const [newData, setNewData] = useState({
        location_name: '',
        address: '',
    });


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isNonMobile ? 400 : 360,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        await axios.post(createLocation, {
            location_name: newData.location_name,
            alamat: newData.address
        }).then((response) => {
            console.log(response.data);
            clearFormData()
            close()
            setLoading(false)
            getLocationData()
            SuccessAlert({
                message: response.data.message
            })
        }).catch((err) => {
            setLoading(false)
            console.log(err.response.data);
            ErrorAlert({
                message: err.response.data.warning
            })
        });

    }

    // Fungsi untuk menyimpan value yang diinput ke variabel newdata
    const handleChange = (e, props) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
        // userId = props.id
        console.log([name], value);
        // dispatch(getDataStaff(newData))
    };

    const clearFormData = () => {
        setNewData({
            location_name:'',
            address: ''
        });
    }
    

    return (
        <div>
            <Modal open={open}>
                <Box sx={style}>
                    <form onSubmit={(e) => handleSubmit(e)} >
                        {/* <Box display="flex" justifyContent="end">
                    <Tooltip title="Tutup">
                      <IconButton onClick={close}>
                        <CloseCircleOutlined
                          style={{ fontSize: '20px', color: 'red' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
                        <Box
                            mb="35px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h4" textAlign="center">
                                Membuat Lokasi Baru
                            </Typography>
                        </Box>
                        <Box
                            justifyContent="center"
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            // p="10px 10px"
                            sx={{
                                '& > div': {
                                    gridColumn: isNonMobile ? undefined : 'span 4',
                                },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Nama Lokasi"
                                // placeholder='giovr'
                                value={newData.location_name}
                                onChange={e => handleChange(e)}
                                name="location_name"
                                autoComplete="off"
                                required
                                sx={{ gridColumn: 'span 4' }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Alamat"
                                // placeholder='giovr'
                                value={newData.address}
                                onChange={e => handleChange(e)}
                                name="address"
                                autoComplete="off"
                                required
                                sx={{ gridColumn: 'span 4' }}
                            />
                        </Box>
                        <Box
                            sx={{
                                // marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                // marginRight: '10px',
                                marginTop: '40px',
                                marginBottom: '5px',
                            }}
                        >
                            <Button
                                // onClick={handleBackButton}
                                color="error"
                                variant="outlined"
                                size='medium'
                                onClick={() => {
                                    close();
                                    clearFormData();
                                }}
                                sx={{
                                    marginRight: '15px',
                                    width: '70px',
                                    fontSize: '13px',
                                }}
                                disabled={loading}
                            >
                                Kembali
                            </Button>
                            <LoadingButton
                                type="submit"
                                size="medium"
                                loading={loading}
                                loadingIndicator="Loading…"
                                variant="contained"
                            >
                                Buat Lokasi
                            </LoadingButton>
                            {/* <Header 
                                locationData={newData.location_name}
                            /> */}
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default CreateLocation;