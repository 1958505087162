import { Box, Button, Card, Container, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import { createLocation, getDataLocation } from '../../apiconfig/ApiConfig'
import Iconify from '../../components/iconify/Iconify'
import CreateLocation from './CreateLocation'
import EditLocation from './EditLocation'

const Location = () => {

  const isNonMobile = useMediaQuery('(min-width:600px)');

  const [dataLocation, setDataLocation] = useState({})
  const [loading, setLoading] = useState(false)
  const [openCreateLocationModal, setOpenCreateLocationModal] = useState(false)
  const [openUpdateLocationModal, setOpenUpdateLocationModal] = useState(false)

  const getLocationData = async () => {
    await axios.post(getDataLocation)
      .then((response) => {
        // console.log('data lokasi ', response.data);
        setDataLocation(response.data)
      }).catch((err) => {
        console.log(err.response);
      });
  }

  useEffect(() => {
    getLocationData()
    handleDisabled()
  }, [])

  const handleCreateLocation =  () => {
    setOpenCreateLocationModal(true)
  }
  
  const handleUpdateLocation =  () => {
    setOpenUpdateLocationModal(true)
  }

  const handleDisabled = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500);
  }

  return (
    <Box>
      <Helmet>
        <title> Location | Parkways easyticket </title>
      </Helmet>

      <Container sx={{ padding: '' }} >
        <Stack direction="row" alignItems="center" justifyContent="start" mb={5}>
          <Typography variant="h6" gutterBottom>
            Management Location
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={dataLocation.length !== 0 ? handleUpdateLocation : handleCreateLocation}
            disabled={loading}
          >
            {dataLocation.length !== 0 ? 'Edit Location' : 'New Location'}
          </Button> */}
        </Stack>

        <Card sx={{ padding: '30px 20px', boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)' }}>
          <Box
            justifyContent="center"
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            p={isNonMobile ? "0px 15px" : "0px 10px"}
            sx={{
              '& > div': {
                gridColumn: isNonMobile ? undefined : 'span 4',

              },
            }}
          >
            <TextField
              name="location_name"
              type="text"
              label="Nama Lokasi. . ."
              variant="standard"
              value={dataLocation.location_name}
              // placeholder='contoh@gmail.com'
              // onChange={e => handleChange(e)}
              InputLabelProps={{ shrink: true }}
              sx={{
                gridColumn: 'span 2',
                // mr: isNonMobile ? '30%' : undefined,
                // mb: '10px'
              }}
              required
              disabled
            />
            <Box sx={{ gridColumn: 'span 2' }}>{''}</Box>
            <TextField
              name="address"
              type="text"
              label="Alamat. . ."
              variant="standard"
              value={dataLocation.alamat}
              // placeholder='contoh@gmail.com'
              // onChange={e => handleChange(e)}
              InputLabelProps={{ shrink: true }}
              sx={{
                gridColumn: 'span 2',
                // mr: isNonMobile ? '30%' : undefined,
                // mb: '10px'
              }}
              required
              disabled
            />
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="end" mt='30px'>
            
            <Button
              variant="contained"
              // startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={dataLocation.length !== 0 ? handleUpdateLocation : handleCreateLocation}
              disabled={loading}
            >
              {dataLocation.length !== 0 ? 'Edit Data' : 'Buat Lokasi'}
            </Button>
          </Stack>
        </Card>
        <CreateLocation 
          open={openCreateLocationModal}
          close={() => setOpenCreateLocationModal(false)}
          getLocationData={() => getLocationData()}
        />
        <EditLocation
          open={openUpdateLocationModal}
          close={() => setOpenUpdateLocationModal(false)}
          getLocationData={() => getLocationData()}
          dataLocation={dataLocation}
        />
        <ToastContainer/>
      </Container>
    </Box>
  )
}

export default Location