// material-ui
import {
    Typography,
    Box,
    Button,
    Modal,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    TextField,
    OutlinedInput,
    IconButton,
    InputAdornment,
} from '@mui/material';

import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';

import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { LoadingButton } from '@mui/lab';
import { createUser, deleteCategory, deleteItem, deleteUser, updateUserData } from '../../apiconfig/ApiConfig';
import ErrorAlert from '../../components/alert/ErrorAlert';
import SuccessAlert from '../../components/alert/SuccessAlert';
import InfoAlert from '../../components/alert/InfoAlert';


const DeleteItem = ({
    open,
    close,
    getDataItem,
    selectedData
}) => {
    const isNonMobile = useMediaQuery('(min-width:600px)');

    // const [loading, setLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        p: 2,
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        await axios.delete(`${deleteItem}/${selectedData.ticketitem_id}`)
            .then((response) => {
                // console.log(response.data);
                setLoading(false)
                getDataItem()
                ErrorAlert({
                    message: response.data.message
                })
                close()
            }).catch((err) => {
                setLoading(false)
                console.log(err.response.data);
            });


    }


    return (
        <div>
            <Modal open={open}>
                <Box sx={style}>
                    <form onSubmit={(e) => handleSubmit(e)} >
                        <Typography sx={{ fontSize: '16px', textAlign: 'center' }} >Yakin ingin menghapus data ?</Typography>
                        <Box
                            sx={{
                                // marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                // marginRight: '10px',
                                marginTop: '20px',
                            }}
                        >
                            <Button
                                // onClick={handleBackButton}
                                color="error"
                                variant="outlined"
                                size='small'
                                onClick={() => {
                                    close();
                                }}
                                sx={{
                                    marginRight: '15px',
                                    // width: '70px',
                                    fontSize: '13px',
                                }}
                                disabled={loading}
                            >
                                Tidak
                            </Button>
                            <LoadingButton
                                type="submit"
                                size="small"
                                loading={loading}
                                loadingIndicator="Loading…"
                                variant="contained"
                            >
                                Ya
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default DeleteItem;