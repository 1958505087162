import { useState } from 'react';
// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';
import FormatRupiah from '../../../components/formatrupiah/FormatRupiah';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  titleStyle = 1,
  title,
  title2,
  description,
  descriptionTrigger,
  currentMonthIncomeDataArray,
  todayIncomeDataArray,
  data1,
  data2,
  totalVisitorIn,
  percentage,
  total,
  total2,
  icon,
  color = 'primary',
  width,
  height,
  sx,
  ...other
}) {
  const handleDescription = () => {
    if (data1 > data2) {
      return (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography sx={{ opacity: 0.72, fontSize: '13px', marginRight: '3px', fontWeight: 'bold' }}>
              {description}
            </Typography>
            <Iconify icon={'ant-design:rise-outlined'} width={20} height={20} color="green" />
            <span style={{ color: 'green', fontWeight: 'bold', fontSize: '13px', marginLeft: '3px' }}>
              {percentage}%
            </span>
            {/* <span style={{fontSize:'9px', marginLeft:'3px', fontWeight:'bolder', marginBottom:'4px'}}>Dari bulan lalu</span> */}
          </Box>
          {/* <span style={{ fontSize: '13px' }}>Perbandingan dengan data kemarin</span> */}
        </>
      );
    }

    return (
      <>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography sx={{ opacity: 0.72, fontSize: '13px', marginRight: '3px', fontWeight: 'bold' }}>
            {description}
          </Typography>
          <Iconify icon={'ant-design:fall-outlined'} width={20} height={20} color="crimson" />
          <span style={{ color: 'crimson', fontWeight: 'bold', fontSize: '13px', marginLeft: '3px' }}>
            {percentage}%
          </span>
        </Box>
        {/* <span style={{ fontSize: '13px' }}>Perbandingan dengan data kemarin</span> */}
      </>
    );
  };

  // console.log('tes 123', todayIncomeDataArray);

  return (
    <Card
      sx={{
        // py: 5,
        padding: '35px 0 10px 0',
        // padding: descriptionTrigger && data1 !== 0 ? '30px 0 10px 0' : '30px 0',
        boxShadow: 3,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      
      <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={width} height={height} />
      </StyledIcon>

      {titleStyle === 1 ? (
        <Box>
          <Typography variant="h4">{total ? FormatRupiah(total) : 0}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72, mt: '3px', mb: '3px' }}>
            {title}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between">
          <Box width="50%">
            <Typography variant="h4">{totalVisitorIn ? FormatRupiah(totalVisitorIn) : 0}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72, mt: '3px', mb: '3px' }}>
              {title}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="h4">{total2 ? FormatRupiah(total2) : 0}</Typography>
            <Typography variant="subtitle2" sx={{ opacity: 0.72, mt: '3px', mb: '3px' }}>
              {title2}
            </Typography>
          </Box>
        </Box>
      )}

      {/* {
          descriptionTrigger === true ?
            handleDescription()
            :
            undefined
      } */}

      {/* {data1 !== 0 ? (descriptionTrigger === true ? handleDescription() : undefined) : undefined} */}
    </Card>
  );
}
